@import 'src/styles/variables';
@import 'src/styles/mixins';

.verification_wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.card {
  border-bottom: 1px solid #e7e7e7;
  font-family: $font-Lato;
}

.image_wrapper {
  position: relative;
  width: 100%;

  &:hover .preview {
    box-shadow: 0 4px 8px 0 rgba(black, 0.3);
  }

  &:hover .artwork_card_hover {
    opacity: 1;
    z-index: 1;
  }
}

.preview {
  width: 100%;
  max-height: 80vh;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: box-shadow 0.2s ease-in-out;
  will-change: box-shadow;
}

.content {
  font-size: 14px;
  line-height: 18px;

  @media screen and (min-width: $desktop) {
    font-size: 16px;
    line-height: 20px;
  }
}

.row {
  display: flex;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0 4px;
  color: $font-color;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header p {
  padding-bottom: 8px;
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 100%;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;

  &__empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }

  @media screen and (min-width: $desktop) {
    font-size: 24px;
  }
}

.price {
  width: 120px;
  flex-shrink: 0;
  margin-left: auto;
  font-size: 20px;
  line-height: 24px;
  font-family: $font-Sen;
  text-align: right;
  color: $primary-color;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.noforsale {
    color: rgba(63, 64, 65, 0.3);
  }

  &.sold {
    color: $error-color;
  }

  @media screen and (min-width: $desktop) {
    line-height: 30px;
  }
}

.link {
  text-decoration: none;
}

.description {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;

  @media screen and (min-width: $desktop) {
    font-size: 16px;
    line-height: 20px;
  }
}

.status {
  text-transform: capitalize;
  flex-basis: 120px;
  flex-shrink: 0;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 0;
  color: $font-color;
  font-family: $font-Sen;
  text-align: right;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  &.verified {
    color: $verified-color;
  }
  &.unverified {
    color: $unverified-color;
  }
  &.pending {
    color: $pending-color;
  }
}

.artwork_card_hover {
  display: flex;
  opacity: 0;
  transition: .3s;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;;
  border-radius: 10px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.7);
}

.button__add {
  position: static;
  bottom: 120px;
  transform: translateX(-27%);
  margin: 0 0 10px 110px;
  width: 200px;
  height: 55px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 800;
  font-family: $font-Muli;

  .icon {
    width: 24px;
    height: 26px;
    margin-right: 20px;
    fill: white;
    z-index: 1;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    max-height: 60px;
    width: 60px;
    border-radius: 50px 0 0 50px;
    background-color: #6a53f8;
    z-index: 1;
  }
}


