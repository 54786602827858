@import 'src/styles/variables';

.subnav {
  overflow: hidden;
  margin-bottom: 10px;
  background: white;
  display: flex;
  box-shadow: 2px 2px 2px 2px rgba(128, 128, 128, 0.5);

  a {
    color: $c-text;
    display: inline-block;
    padding: $scale 0;
    margin: 0 20px;
    text-decoration: none;
    &.active {
      color: $primary-color;
      border-bottom: 3px solid $primary-color;
    }
  }

  @media screen and(max-width: 640px) {
    width: 100%;
    box-shadow: 0px 2px 2px 0px rgba(128, 128, 128, 1);
    border-top: 1px solid $c-border;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.subnav_wrapper {
  margin: 1em;
  margin-bottom: 0;
}
