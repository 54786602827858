@import 'src/styles/variables';
@import 'src/styles/mixins';

.artwork {
  & > .image__wrapper {
    display: none;
  }

  .image__mobile__wrapper {
    position: relative;

    .delete_button {
      color: black;
      align-self: end;
      margin-top: 10px;
    }
  }

  @media screen and (min-width: $tablet) {
    display: flex;
    align-items: flex-start;
    padding: 0;

    & > .image__wrapper {
      display: block;
    }
  }

  .image {
    position: relative;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    background-color: $border-color;
    width: 140px;
    height: 140px;

    @media screen and (max-width: $tablet) {
      width: 100%;
      height: calc(100vw - 40px);
    }

    &__wrapper {
      position: relative;
      width: 100%;
      //margin-right: 15px;
      flex-shrink: 0;

      @media screen and (min-width: $tablet) {
        width: 220px;
        flex-shrink: 0;
        margin-right: 43px;
      }

      &::after {
        position: absolute;
        content: '';
        height: 30px;
        left: 17px;
        right: 17px;
        bottom: 0;
        opacity: 0.7;
        background-color: #fff;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
      }
    }

    @media screen and (min-width: $tablet) {
      width: 220px;
      height: 220px;
      border-radius: 10px;
      background-color: #cecece;
    }
  }

  .content {
    @media screen and (min-width: $tablet) {
      flex-grow: 1;
    }
  }

  .header {
    @media screen and (min-width: $tablet) {
      .image__wrapper {
        display: none;
      }

      .delete_button {
        color: black;
        margin-top: 10px;
        align-self: end;
      }
    }

    &__content {
      display: none;

      @media screen and (min-width: $desktop) {
        display: flex;
        padding: 1rem 0;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
      }
    }

    &__content_watchlist {
      display: none;

      @media screen and (min-width: $desktop) {
        position: relative;
        display: flex;
        padding: 1rem 0;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
      }
      &__wrapper_price {

        @media screen and (min-width: $tablet) {
          position: absolute;
          bottom: 7px;
          right: 0;

        }
      }
      &__price {
        font-size: 20px;
        line-height: 24px;
        font-weight: 800;
        color: $primary-color;

        @media screen and (min-width: $tablet) {
          font-size: 28px;
          line-height: 35px;

        }
      }

      &__prev_price {
        position: relative;
        &:before {
          border-bottom: 3px solid red;
          position: absolute;
          content: "";
          width: 100%;
          height: 50%;
          transform: rotate(-7deg);
        }
      }
    }

    &__mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      @include media-up($desktop) {
        display: none;
        margin-bottom: 0;
      }
    }
  }

  .title {
    font-size: 28px;
    line-height: 24px;
    font-family: $font-Sen;
    font-weight: 400;
    text-decoration: none;
    color: $font-color;
    word-break: break-all;
    @include text-hover-primary;

    @media screen and (min-width: $desktop) {
      font-size: 28px;
      line-height: 34px;
    }

    &__mobile {
      font-family: $font-Sen;
      margin-top: 10px;
      font-size: 28px;
      font-weight: 400;
    }
  }

  .name,
  .address {
    font-size: 16px;
    line-height: 24px;
    font-style: italic;
    word-break: break-all;

    @media screen and (min-width: $desktop) {
      line-height: 20px;
    }
  }

  .name {
    color: var(--primary-color, $primary-color);
    font-weight: 800;

    @media screen and (min-width: $desktop) {
      font-weight: bold;
    }
  }

  .params {
    display: none;
    @media screen and (min-width: $desktop) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 18px 0 18px 0;
      border-bottom: 1px solid $border-color;
      margin-bottom: 10px;
    }
    &_mobile {
      border-top: 1px solid $border-color;
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
      @media screen and (min-width: $desktop) {
        display: none;
      }

      .param_mobile {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5em;
      }
    }
  }

  .param {
    font-size: 14px;
    font-family: Mulish, serif;
    margin-right: 10px;

    &__weight {
      width: 75px;
    }

    &__label {
      display: inline-block;
      font-weight: bold;
      color: #c7c7c7;
      width: 100px;
      font-size: 14px;

      @include media-up($tablet) {
        line-height: 18px;
        margin-bottom: 0.5rem;
      }
    }

    p {
      @media screen and (min-width: $tablet) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    @include media-up($tablet) {
      max-width: 180px;
    }
  }
}

.content {
  &__mobile {
    display: flex;
    flex-direction: column;

    &__text {
      font-size: 14px;
      font-style: italic;
      word-break: break-all;
      width: 100vh;
    }

    &__wrapper_price {
      margin-top: 5px;
      margin-bottom: 20px;
    }

    &__author {
      font-size: 14px;
      color: var(--primary-color, $primary-color);
      font-weight: 800;
    }

    &__address {
      font-size: 12px;
      line-height: 24px;
      font-style: italic;
      word-break: break-all;
    }

    @include media-up($desktop) {
      display: none;
    }
  }

  &__desktop {
    @include media-down($desktop) {
      display: none;
    }
  }
}
