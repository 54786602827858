@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;

  .cropper img {
    max-height: 450px;

    @media screen and (min-width: $desktop) {
      max-height: 400px;
    }
  }

  .spinner {
    position: absolute;
  }

  .image {
    width: auto;
    max-height: 450px;

    &__wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 450px;
      font-size: 0;
      border-radius: 15px;
      background-color: #f7f7f7;

      @media screen and (min-width: $desktop) {
        margin-bottom: 30px;
      }

      .check {
        width: 26px;
      }
    }

    &__tools {
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);

      @media screen and (min-width: $desktop) {
        bottom: 30px;
      }
    }
  }
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 350px;
  border: 2px dashed #e7e7e7;
  border-radius: 15px;
  padding-top: 80px;
  margin-bottom: 30px;
  text-align: center;

  @media screen and (min-width: $desktop) {
    height: 450px;
  }

  &.hide {
    display: none;
  }

  @media screen and (min-width: $iPadPro) {
    .mobile {
      display: none;
    }
  }

  .desktop {
    display: none;

    @media screen and (min-width: $iPadPro) {
      display: block;
    }
  }

  &__image {
    width: 80px;
    height: 80px;
    margin-bottom: 35px;
  }

  p {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  &__label {
    display: inline-block;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 29px;
    font-family: $font-Sen;
    font-weight: bold;

    @media screen and (min-width: $iPadPro) {
      margin-bottom: 0;
      font-size: 28px;
      line-height: 34px;
    }
  }

  input {
    display: none;
  }

  .browse {
    display: inline-block;
    padding: 0 5px;
    font-weight: 800;
    text-transform: lowercase;
    color: $primary-color;
    cursor: pointer;
  }

  .requirements {
    display: inline-flex;
    flex-direction: column;
    max-width: 224px;
    font-size: 14px;
    line-height: 24px;
    color: rgba(63, 64, 65, 0.4);

    @media screen and (min-width: $desktop) {
      max-width: none;
      font-size: 16px;
      line-height: 28px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.activeImage {
  border: 0;
  box-shadow: 0 0 0 4px $primary-color;
}

.gallery {
  @include with-scroll;

  display: flex;
  padding: 30px 4px;
  margin-left: -10px;
  margin-right: -10px;
  overflow-x: auto;
  justify-content: space-between;

  &__item {
    position: relative;
    display: inline-block;
    flex-basis: 70px;
    flex-shrink: 0;
    height: 70px;
    margin-bottom: 0;
    border: 2px dashed #e7e7e7;
    border-radius: 10px;
    cursor: pointer;

    &:hover .delete {
      display: inline-flex;
    }

    &--default {
      &::after {
        position: absolute;
        display: inline-block;
        content: 'Default';
        bottom: -28px;
        left: 50%;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 800;
        line-height: 28px;
        color: $primary-color;
        transform: translateX(-50%);
      }
    }

    &--dragg {
      border: 0;
      box-shadow: 0 0 0 4px lighten($color: $primary-color, $amount: 10);
    }

    .img {
      border-radius: 10px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .delete {
      display: none;
      position: absolute;
      top: -10px;
      right: -10px;
      align-items: center;
      justify-content: center;
      padding: 6px;
      border-radius: 5px;
      border: 0;
      background-color: $font-color;
      color: white;
    }

    &.add {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed #e7e7e7 !important;
      box-sizing: border-box !important;
    }
  }

  &__notempty {
    @media screen and (min-width: $desktop) {
      justify-content: center;
    }

    .gallery__item {
      border: 0;
      box-sizing: content-box;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__draggIn {
    background-color: #f7f7f7;
  }
}
