@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
  position: relative;
  margin-top: 5rem;
}

.footer_wrapper {
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 2;

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 80px;
    align-items: center;
  }
}

.actions {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  color: $primary-color;
  font-weight: 800;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @include media-up($desktop) {
    flex-direction: row;
    font-size: 24px;
  }

  &:before {
    content: 'Total';
    font-size: 14px;
    font-weight: 700;

    @include media-up($desktop) {
      content: 'Total:';
      font-size: 24px;
      font-weight: 800;
      margin-right: 0.5rem;
    }
  }
}

.button {
  justify-content: space-between !important;
  padding-left: 2rem;
  padding-right: 2rem;

  @include media-up($desktop) {
    min-width: 140px !important;
  }

  & > *:first-child:last-child {
    margin-left: auto;
    margin-right: auto;
  }
}

.reverse {
  transform: rotate(180deg);
}

.shadow {
  position: absolute;
  left: 36px;
  right: 36px;
  height: 70px;
  top: 0px;
  margin: 0 auto;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1;

  @media screen and (min-width: $desktop) {
    height: 70px;
  }
}
