@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 14px;

  &.required .label::after {
    position: relative;
    content: '*';
    color: $error-color;
    left: 5px;
  }

  .label {
    margin-bottom: 6px;
    padding-left: 5px;
    font-size: 14px;
    line-height: 28px;
    font-family: inherit;
    font-weight: bold;
    text-transform: unset;
    color: inherit;
  }

  .textarea {
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;

    font-size: inherit;
    line-height: 28px;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    resize: none;

    @include with-scroll;

    &.split {
      border: 0;
      padding: 0;
      line-height: 1.2;
      text-decoration: underline;
    }

    &.box {
      background-color: #fafafa;
      border: 1px solid #999;
      border-radius: 10px;
    }

    &::placeholder {
      color: inherit;
      text-decoration: none;
      opacity: 0.3;
      font-style: italic;
    }

    &:focus {
      outline: none;
      border-color: $primary-color;
    }

    &:disabled {
      opacity: 0.4;
      resize: none;
    }
  }

  .control {
    position: relative;
  }

  .count {
    position: absolute;
    display: inline-block;
    top: 21px;
    right: 5px;
    font-size: 14px;
    line-height: 18px;
    color: rgba(63, 64, 65, 0.3);
  }

  .error {
    margin-top: 6px;
    font-size: 14px;
    line-height: 16px;
    color: $error-color;
  }
}
