@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  position: relative;
  padding: 1rem 0 1rem 0;

  @include media-up($desktop) {
    padding-top: 9rem;
  }

  .container {
    margin-top: 20px;
    position: relative;
    z-index: 1;

    @media screen and (min-width: $desktop) {
      margin-top: 0;
    }
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 440px;
    background: linear-gradient(
      180deg,
      rgba(250, 250, 250, 0) 0%,
      #fafafa 98.44%
    );

    @media screen and (min-width: $desktop) {
      height: 210px;
    }
  }

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    top: 440px;
    background-color: #fafafa;

    @media screen and (min-width: $desktop) {
      top: 210px;
    }
  }
}
