@import '../../../styles/variables';

.post_card {
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease-in-out;
  will-change: box-shadow;
  flex: 1;

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;

  .featured_image {
    img {
      object-fit: cover;
      height: 200px;
      width: 100%;
      border-top-right-radius: 10px 10px;
      border-top-left-radius: 10px 10px;
    }
  }

  .title {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-family: $font-Sen;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 0.5em;
    word-break: break-all;
  }

  .author {
    color: $primary-color;
    display: inline-block;

    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.5em;
  }

  .card_content {
    padding: 40px 20px;

    .post_content {
      line-height: 26px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 6;
      text-overflow: ellipsis;
    }
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(black, 0.3);
  }
}
