@import 'src/styles/variables';

.root {
}

.dropzone {
  margin-bottom: 1.5rem;
  height: 250px;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 220px;
  margin: 22px auto 0 auto;
}

.tags_field {
  .icon {
    margin-top: 30px;
    width: 16px;
    height: 16px;

    svg {
      path {
        fill: $primary-color;
      }
    }

    &:disabled {
      cursor: initial;

      svg {
        path {
          fill: #e7e7e7;
        }
      }
    }
  }
}
