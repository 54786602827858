.wrapper {
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    margin: 20px 10px;
}