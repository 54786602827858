@import 'src/styles/variables';
@import 'src/styles/mixins';

.avatar {
  display: flex;
  align-items: center;
  color: $font-color;

  @media screen and (min-width: $desktop) {
    display: none;
  }

  &__imgage_menu {
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 15px;
    flex-shrink: 0;

    img {
      width: 60px;
      height: 60px;
      position: relative;
      background-color: #c4c4c4;
      border: 0;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;
  }

  &__name {
    font-size: 20px;
    line-height: 24px;
    font-family: $font-Sen;
    color: $font-color;
    text-align: left;
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: var(--primary-color, $primary-color);
    text-decoration: none;
  }

  &__shadow {
    position: absolute;
    height: 11px;
    left: 11px;
    right: 11px;
    bottom: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 0;
  }
}
