@import '../../../styles/mixins';

.grid {
  display: grid;
  grid-column-gap: 26px;

  @include media-up($desktop) {
    grid-column-gap: 50px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  grid-row-gap: 26px;

  @include media-up($desktop) {
    grid-row-gap: 50px;
  }
}
