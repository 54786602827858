@import '../../../styles/variables';
@import '../../../styles/mixins';

.header_wrapper {
  width: 100%;
  background: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  .header {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      font-family: $font-Sen;
      font-size: 28px;
      line-height: 34px;
    }
    .progress {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 550px;
      .step {
        font-family: $font-Muli;
        font-style: italic;
        font-weight: normal;
        font-size: 16px;
        color: rgba($font-color, 0.4);
        display: flex;
        align-items: center;

        .name {
          @include media-down($desktop) {
            overflow: hidden;
            max-width: calc(100% - 30px);
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &.active {
          color: $font-color;
        }

        &.last .name {
          max-width: 100%;
        }

        .arrow {
          margin: 0 8px;
          width: 10px;
          height: auto;
          position: relative;
          top: 2px;

          @include media-up($desktop) {
            margin: 0 40px;
            position: static;
            width: 25px;
          }
        }
      }
    }
  }
  .mobile_header {
    display: none;
  }
  @media only screen and (max-width: $desktop) {
    height: 140px;
    flex-direction: column;
    .header {
      background: #f7f7f7;
      min-height: 60px;
      .title {
        display: none;
      }
      .progress {
        @media only screen and (max-width: $desktop) {
          min-width: 0px;
          width: 100%;
          .step {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        @media only screen and (max-width: 320px) {
          .step {
            font-size: 12px;
            .small_arrow {
              margin: 0 5px;
            }
          }
        }
      }
    }
    .mobile_header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      .mobile_title {
        font-family: $font-Sen;
        font-size: 28px;
        line-height: 34px;
      }
      .sub_stepper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .step {
          width: 12px;
          height: 12px;
          border-radius: 12px;
          margin: 5px;
          background-color: lighten($color: $primary-color, $amount: 20);
          &.active {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}
