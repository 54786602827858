@import 'src/styles/variables';

.root {
  width: 800px;
  height: 565px;
  color: $font-color;
  position: relative;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  &__content {
    position: relative;
    padding: 30px 45px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 2;
    .logo {
      width: 110px;
      height: 40px;
      position: absolute;
    }
    .artwork {
      text-align: center;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
      .header {
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        height: 41px;
        text-align: center;
      }
      .subheader {
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
      }
      .image {
        width: 180px;
        height: 180px;
        margin: 20px;
        border-radius: 5px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        object-fit: cover;
      }
      .title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 0.5rem;
      }
      .author {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
        text-align: center;
      }
      .price {
        font-style: normal;
        font-weight: 800;
        font-size: 22px;
        color: $primary-color;
        white-space: nowrap;
      }
    }
    .column {
      max-width: 180px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      position: absolute;
      bottom: 30px;

      &.left {
        left: 45px;
      }

      &.right {
        right: 45px;
      }

      .description {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        text-align: justify;
        margin-bottom: 1rem;
      }
      .underline {
        width: 180px;
        text-align: center;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid $font-color;
      }
    }
  }

  img {
    display: inline-block;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
}

.date {
  white-space: nowrap;
}
