@import 'src/styles/variables';
@import 'src/styles/mixins';
.wrapper {
  width: 100%;

  &.required .label::after {
    position: relative;
    content: '*';
    color: $error-color;
    left: 5px;
  }

  .input_wrapper {
    position: relative;

    .input {
      width: 100%;
      padding: 11px 20px;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      font-family: inherit;
      font-size: 16px;
      line-height: 28px;
      color: inherit;
      box-sizing: border-box;

      &:focus {
        border: 1px solid $primary-color;
      }

      &.flat {
        padding: 2px 8px 4px 0;
        border: 0;
        text-align: inherit;
        font-size: 28px;
        line-height: 35px;
        font-weight: 800;
        color: #806bff;
        text-decoration: underline;
      }
    }

    .endpoint {
      position: absolute;
      display: inline-block;
      top: 35%;
      right: 15px;
      line-height: 1;
      transform: translateY(-50%);
      color: #c7c7c7;
      z-index: 1;
    }
  }

  .label {
    position: relative;
    bottom: 6px;
    padding-left: 5px;
    font-size: 14px;
    line-height: 28px;
    font-family: inherit;
    font-weight: bold;
    text-transform: unset;
    color: inherit;

    &.none {
      display: none;
    }
  }

  .error {
    position: relative;
    bottom: 18px;
    font-size: 14px;
    line-height: 16px;
    color: #f44336;
    white-space: normal;

    &.flat {
      top: -4px;
    }
  }
}

