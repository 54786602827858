@import 'src/styles/variables';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  &.required .label::after {
    position: relative;
    content: '*';
    color: $error-color;
    left: 5px;
  }

  .label {
    margin-bottom: 6px;
    padding-left: 5px;
    font-size: 14px;
    line-height: 28px;
    font-family: inherit;
    font-weight: bold;
    text-transform: unset;
    color: inherit;
  }

  .control {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .datepicker {
    width: 100%;
  }

  .root {
    width: 100%;
    flex-direction: row-reverse;
    border: 1px solid #e7e7e7;
    border-radius: 5px;

    &.flat {
      border: 0;
      border-radius: 0;
      font-size: 18px;

      input {
        padding: 0;
        text-decoration: underline;
      }

      button {
        width: auto;
        padding-left: 0;
        padding-right: 10px;
        background-color: transparent;
      }
    }

    input {
      padding: 12px 10px 11px 20px;
      line-height: 28px;
      height: auto;
    }

    div {
      margin-left: 0;
    }
  }

  .underline::after,
  .underline::before {
    display: none;
  }

  .adornment {
    margin-left: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    padding: 15px 0;
    border-radius: 5px 0 0 5px;
    background-color: #e7e7e7;

    &:hover {
    }
  }

  .error {
    margin-top: 6px;
    font-size: 14px;
    line-height: 16px;
    color: $error-color;
    white-space: normal;
  }
}
