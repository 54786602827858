@import '../../../styles/variables';

.auth {

  padding-top: 0;
  // padding-bottom: $scale4;
  background-color: white;

  h1 {

    //color: white;
    text-align: center;
    font-size: $scale2;
    margin-bottom: $scale1;
    color: #8a8a8a;

  }

  .card {

     margin: 0 auto;
    overflow: hidden;
    background-color: #f5f7fa;

  }

  .panel-row:nth-child(2) {

    padding-top: 0;
    padding-bottom: 0;
    background-color: white;

  }

  .nav-header {
    background-color: #f5f7fa;
  }

  @media (min-width: $mobile){

    .card {

      width: $scale15;
      margin: 0 auto;

    }
  }
}
