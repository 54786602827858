@import 'src/styles/variables';
@import 'src/styles/tabs';

.row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;
    margin-top: 30px;

    @media screen and (min-width: $desktop) {
        margin-top: 42px;

        &>* {
            flex: 1 1 50%;
        }
    }
}

.content {
    flex: 1 1;

    @media screen and (min-width: $desktop) {
        margin-right: 100px;
    }
}

.title {
    display: flex;
    flex-wrap: wrap;
    font-size: 24px;
    line-height: 39px;
    font-family: $font-Sen;
    font-weight: bold;

    @media screen and (min-width: $desktop) {
        font-size: 32px;
        line-height: 38px;
    }
}

.description {
    font-size: 28px;
    line-height: 36px;
    font-family: $font-Sen;
    .text {
      margin-top: 2px;
    }

    @media screen and (min-width: $desktop) {
        font-size: 40px;
        line-height: 48px;
    }
}


.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    &__wrapper {
        position: relative;
        width: 100%;
        border-radius: 20px;
        min-height: 240px;
        background-color: #F8F8F8;
        order: 0;
        overflow: hidden;

        @media screen and (min-width: $desktop) {
            height: 470px;
            order: 1;
            max-width: 50%;
        }
    }

    &__wrapper:hover &__upload {
        visibility: visible;
        opacity: 1;
    }

    @media screen and (max-width: $desktop) {
        &__wrapper.edit &__upload {
            visibility: visible;
            opacity: 1;
        }
    }

    &__upload {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease-in-out;
    }
}

