@import 'src/styles/variables';

.wrapper {
  width: 49%;
  height: 270px;
  background: #F8F8F8;
  border-radius: 15px;
  padding: 35px;
  margin: 20px 0;
  @media screen and (max-width: 750px){
    padding: 0;
    width: 100%;
    height: 370px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 750px){
    flex-direction: column;
  }
}

.image {
  width: 180px;
  height: 180px;
  @media screen and (max-width: 750px){
    width: 100%;
  }
}

.content {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 750px){
    margin-left: 0;
    padding: 15px;
  }
  &__title {
    margin-bottom: 10px;
  }

  &__date {
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 10px;
  }
}
