@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  position: sticky;
  top: 0;

  height: 50px;

  // remove
  margin: 0;
  padding: 0;

  font-family: $font-Muli;

  background-color: #fff;
  z-index: 5;
  transition: transform 0.3s ease-in-out;

  @include media-up($desktop) {
    height: 80px;
  }

  //nav {
  //  overflow-y: auto;
  //}

  @media screen and (max-width: $desktop) {
    &.hide {
      z-index: 4;
    }
  }
}
.artist_collector_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;

  @include media-up($desktop) {
    display: none;
  }
}

.search {
  &__desktop {
    display: none;

    @media screen and (min-width: ($desktop + 280)) {
      display: flex;
      //flex-grow: 1;
      margin-left: 40px;
      margin-right: 20px;
      width: 330px;
    }
  }

  &__mobile {
    display: flex;
    position: fixed;
    width: 310px;
    top: 0;
    height: 100vh;
    background: #fff;
    z-index: 5;
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;

    &.open {
      overflow: hidden;
      transform: translateX(0);
    }

    @media screen and (min-width: $desktop) {
      display: none;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  background-color: #fff;

  @include media-up($desktop) {
    min-height: 80px;
  }
}

.logo {
  &__nav {
    width: auto;
    box-sizing: content-box;
    margin-top: 3px;
  }

  &.hide {
    opacity: 0;
  }

  @media screen and (min-width: $desktop) {
    &.hide {
      opacity: 1;
    }
  }

  @include media-down($desktop) {
    &__mobile {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  color: $font-color;

  a {
    display: inline-flex;
    flex-shrink: 0;
  }

  &:not(.subnavigation) > * {
    border-right: 0.5px solid #e7e7e7;
  }

  &:not(.subnavigation) a {
    width: auto !important;
    flex-grow: 1;
    padding: 0 24px;
    max-width: 100%;
  }

  .logout {
    padding-left: 24px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    button {
      width: 100%;
    }

    @media screen and (min-width: $desktop) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 120vh;
    width: 310px;
    background-color: #fff;

    @media screen and (max-width: ($desktop - 0.2)) {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: 2;
    }

    .logo {
      padding: 20px 0;
      margin-right: 18px;
      align-self: flex-end;

      @media screen and (min-width: $desktop) {
        display: none;
      }
    }

    .avatar {
      margin-left: 20px;
      margin-bottom: 40px;
    }

    @media screen and (min-width: $desktop) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      height: auto;
      flex: 4;
    }

    &.opened {
      box-shadow: 0 0 30px rgba(black, 0.3);

      @media screen and (max-width: ($desktop - 0.2)) {
        overflow: auto;
        min-height: 480px!important;
        transform: translateX(0);
      }

      @media screen and (min-width: $desktop) {
        box-shadow: none;
      }
    }

    @media screen and (min-width: $desktop) {
      flex-direction: row;
    }
  }

  &__shadow {
    position: absolute;
    display: none;
    top: 0;
    right: -45px;
    bottom: 0;
    content: '';
    width: 45px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 88.07%
    );
    will-change: opacity;
    visibility: hidden;

    &.opened {
      opacity: 1;
    }

    @media screen and (min-width: $desktop) {
      display: none;
    }
  }

  .button {
    font-size: 16px;
    font-family: $font-Muli;
    padding: 11px 12px;

    @media screen and (min-width: $desktop) {
      min-width: 100px;
    }
    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

  @media screen and (min-width: $desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.subnavigation {
  display: none;

  @media screen and (min-width: $desktop) {
    display: flex;
    align-self: stretch;

    & > * {
      margin-left: 15px;
      align-self: stretch;
    }
  }
}

.list {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;

    &:last-child {
      border-bottom: 1px solid #e7e7e7;
    }

    @media screen and (min-width: $desktop) {
      border-top: 0;

      &:last-child {
        border-bottom: 0;
      }
    }

    .extend {
      align-self: stretch;
      align-items: center;
      justify-content: center;
      min-width: 55px;
      border-left: 1px solid #e7e7e7;

      @media screen and (min-width: $desktop) {
        display: none;
      }
    }

    &.onlymobile {
      @media screen and (min-width: $desktop) {
        display: none;
      }
    }

    &.carticon {
      position: relative;
    }

    &:hover {
      & > a:not(.button) {
        color: var(--primary-color, $primary-color) !important;

        @include media-up($desktop) {
          font-weight: 900;
        }
      }
    }
  }
}

.submenu {
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;

  &.opened {
    transform: translateX(0);

    @media screen and (min-width: $desktop) {
      transform: translateY(100%);

      &::after {
        display: block;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    width: 100%;
    transform: translateY(-80px);
    transition: none;
  }
}

.icon {
  width: 26px;
  height: 26px;
  margin: 5px;

  &__counter {
    color: var(--primary-color, $primary-color);
    font-weight: 700;
    position: absolute;
    top: 50%;
    right: -7px;

    &.mobile {
      margin-top: 10px;
      font-size: 14px;
      right: 0;
    }
  }
}

.cart,
.person {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  box-sizing: content-box;
  color: var(--primary-color, $primary-color);
}

.cart {
  position: relative;

  path {
    fill: var(--primary-color, $primary-color);
  }

  .icon__counter {
    top: 30%;
  }

  img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.link {
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 55px;
  color: inherit !important;
  text-decoration: none;
  font-family: $font-Sen;
  font-size: 20px;
  cursor: pointer;
  .icon {
    margin-right: 15px;
    path {
      fill: $primary-color;
    }
    @media screen and (min-width: $desktop) {
      display: none;
    }
  }

  @media screen and (min-width: $desktop) {
    justify-content: center;
    min-height: 80px;
    font-size: 16px;
    font-family: $font-Muli;
    font-weight: bold !important;
    width: auto;
  }

  &.mobile {
    display: flex;

    @media screen and (min-width: $desktop) {
      display: none;
    }
  }

  &.desc {
    display: none;

    @media screen and (min-width: $desktop) {
      display: flex;
    }
  }
}

.menu_item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-width: 120px;
  color: inherit !important;
  text-decoration: none;
  font-family: $font-Muli;
  font-weight: bold !important;
  font-size: 20px;
  transition: color 0.2s ease-in-out;
  will-change: color;
  border-bottom: 1px solid #e7e7e7;
  border-top: 2px solid transparent;

  &:hover {
    color: var(--primary-color, $primary-color)!important;
  }

  &.active {
    color: var(--primary-color, $primary-color)!important;

    @media screen and (min-width: $desktop) {
      border-bottom: 2px solid $primary-color;
    }
  }
}

.shadow {
  position: absolute;
  left: 36px;
  right: 36px;
  height: 40px;
  top: 0;
  margin: 0 auto;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1;

  @media screen and (min-width: $desktop) {
    height: 70px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(63, 64, 65, 0.5);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.toggle {
  order: -1;

  &__navigation {
    align-self: flex-start;
    position: absolute;
    left: 20px;
    top: 15px;

    @media screen and (min-width: $desktop) {
      display: none;
    }
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}


.person {
  margin-left: 20px;

  @include media-up($desktop) {
    display: none;
  }
}

.socials {
  margin-top: 15px;
  margin-bottom: 0;

  @media screen and (max-height: $iPhone6) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: $desktop) {
    display: none !important;
  }
}

.back {
  order: -1;

  svg {
    width: 24px;
    height: auto;
  }

  &:hover path {
    fill: $primary-color;
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.profileDropdown {
  display: flex;
  align-items: center;
  padding: 0 12px 0 7px;
  border: 1px solid $border-color;
  height: 50px;
  border-radius: 25px;

  &:hover,
  &:focus {
    background-color: var(--primary-color, #beafe5);
    color: white;
  }

  .img {
    border-radius: 50%;
    background-color: $border-color;
    width: 36px;
    height: 36px;
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
}

.download {
  border-radius: 50%;
  background-color: $primary-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 19px;
  height: 19px;
  margin-left: auto;
  margin-right: 14px;

  @include media-up($desktop) {
    display: none;
  }
}
