@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
}

.header {
  margin-bottom: 2rem;
  text-align: right;
}

.preview {
  position: relative;
  height: 500px;

  display: flex;
  align-items: flex-end;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 14px;
  margin-left: -20px;
  margin-right: -20px;

  @include media-up($tablet) {
    align-items: center;
    padding: 46px;
    margin-left: 0;
    margin-right: 0;
    height: 380px;
  }

  @include media-up($desktop) {
    height: 600px;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;

  @include media-up($tablet) {
    border-radius: 5px;
  }
}

.title {
  font-family: $font-Sen;
  font-size: 30px;
  line-height: 38px;
  font-weight: 800;
  color: #fff;
  position: relative;
  z-index: 2;
  text-align: center;
  word-wrap: break-word;
  width: 100%;

  @include media-up($tablet) {
    text-align: left;
    max-width: 300px;
  }

  @include media-up($desktop) {
    font-size: 44px;
    line-height: 58px;
    max-width: 540px;
  }

  @media screen and (max-width: $tablet) {
    font-size: 24px;
  }
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;

  & > *:first-child {
    height: 300px;
  }

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
}
