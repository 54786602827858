.artwork-sales-history {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .history-header {
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: #3F4041;
  }
  .info-block-history {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1024px) {
      margin: 330px 0 0 235px;
    }
    @media (max-width: 823px) {
      margin: 330px 0 0 170px;
    }
    @media (max-width: 768px) {
      margin: 330px 0 0 150px;
    }
    @media (max-width: 736px) {
      margin: 330px 0 0 140px;
    }
    @media (max-width: 667px) {
      margin: 330px 0 0 120px;
    }
    @media (max-width: 731px) {
      margin: 330px 0 0 90px;
    }
    @media (max-width: 414px) {
      margin: 330px 0 0 30px;
    }
  }
  .info-wrapper {
    display: flex;
    .sales-chart {
      width: 50%;
      margin: 0 0 70px 80px;
      @media (max-width: 1400px) {
        width: 65%;
        margin: 0 0 70px -90px;
      }
      @media (max-width: 1024px) {
        width: 0;
        margin: 0 0 0 0;
      }
      @media (max-width: 414px) {
        width: 0;
        margin: 0 0 0 -20px;
      }
      @media (max-width: 375px) {
        width: 0;
        margin: 0 0 0 -10px;
      }
    }
  }
}

.info-block-wrapper {
  padding: 20px 60px;
  @media (max-width: 1400px) {
    padding: 20px 50px;
  }
  @media (max-width: 1030px) {
    padding: 20px 0;
  }
  .info-price {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 30px;
    color: #806BFF;
    margin-left: 20px;
    margin-bottom: 0;
  }
  .info-price-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #3F4041;
    margin-left: 20px;
    margin-bottom: 0;
    @media (max-width: 375px) {
      font-size: 19px;
    }
  }
  .info-block {
    border-left: 2px solid #E7E7E7;
  }
}
