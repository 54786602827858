@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputs_wrapper {
  margin: 0 -15px;

  @include media-up($tablet) {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  & > * {
    padding: 0 15px;
  }
}

.add_button {
  display: block;
  width: 210px;
  height: 60px;
  background: $primary-color;
  border-radius: 50px;
  margin: 0 auto;

  font-family: $font-Sen, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: white;
  transition: 400ms;

  &:not(:disabled):hover {
    background-color: darken($primary-color, 5%);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.maxWidth {
  max-width: 400px!important;
}
