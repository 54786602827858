@import '../../styles/variables';
@import '../../styles/mixins';

$breakpoint: $tablet;

.content {
  @media screen and (min-width: $breakpoint) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include media-up($desktop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  .card {
    padding: 50px 0 20px;
    border-radius: 10px;
    background-color: #fff;
    border-bottom: 4px solid transparent;
    margin-bottom: 20px;

    @include media-up($breakpoint) {
      padding-left: 24px;
      padding-right: 24px;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    @include media-up($tablet) {
      &:hover {
        box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.07);
        border-bottom-color: $primary-color;
      }
    }

    @media screen and (min-width: $breakpoint) {
      max-width: 370px;
    }

    @include media-up($desktop) {
      flex-basis: 390px;
      max-width: none;
    }

    &__image {
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 17px;
      font-size: 28px;
      line-height: 34px;
      font-family: $font-Sen;
      font-weight: normal;
      text-align: center;
    }

    &__description {
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
      text-align: center;

      @include media-up($breakpoint) {
        text-align: left;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-bottom: 30px;

  border-bottom: 1px solid #e7e7e7;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (min-width: $breakpoint) {
    border-bottom: 0;
    margin-top: 30px;
    padding-bottom: 0;
  }
}
