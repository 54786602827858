@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 95px;
}

.container {
  display: flex;
  width: 100%;
  height: 434px;

  @include media-down($tablet) {
    height: 343px;
  }

  @include media-down($mobile) {
    height: 649px;
  }

  @include media-down($tablet) {
    align-items: center;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-top: 123px;
  margin-right: 20px;

  @include media-down($desktop) {
    margin-left: 56px;
  }

  @include media-down($tablet) {
    margin-left: 36px;
    margin-top: 0;
  }

  @include media-down($mobile) {
    margin-left: 26px;
    margin-top: 0;
  }
}

.text_container {
  max-width: 760px;
  text-align: left;

  .title {
    color: rgba(255, 255, 255, 1);
    font-family: $font-Sen;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 16px;
    margin-top: 0;
  }

  @include media-down($mobile) {
    max-width: 320px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-Sen;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
  height: 60px;
  border-radius: 40px;
  background-color: $primary-color;
  min-width: 200px;
  padding-left: 2rem;
  padding-right: 2rem;
  align-self: center;

  @include media-up($desktop) {
    color: $font-color;
    border: 1px solid $border-color;
    background-color: #fff;
    order: 1;
  }

  &:hover {
    background-color: darken($primary-color, 5%);

    @include media-up($desktop) {
      background-color: darken(#fff, 5%);
    }
  }
}

.button {
  margin-top: 30px;
}
