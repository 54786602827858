@import 'src/styles/variables';
@import 'src/styles/mixins';

.content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  .form_wrapper {
    width: 100%;
    max-width: 450px;

    .input {
      width: 90%;
      height: 110px;
      margin-bottom: 15px;
      @include media-down($tablet) {
        width: 100%;
        margin-bottom: 25px;
      }

      .endpoint {
        right: -30px;
      }
    }

    @include media-up($tablet) {
      max-width: 350px;
      margin-right: 60px;
      .input {
        &__label {
          display: block;
          margin-bottom: 6px;
          padding-left: 5px;
          font-size: 14px;
          line-height: 28px;
          font-family: inherit;
          font-weight: bold;
          text-transform: unset;
          color: black;
        }
      }
    }
  }
}

.image_wrapper {
  display: none;
  @include media-up($tablet) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    .image {
      max-width: 300px;
      max-height: 283px;
      margin-bottom: 20px;
    }
  }
}
