@import 'src/styles/variables';

.page__title {
  text-align: left !important;
  margin-top: 40px !important;
}

.title {
  text-align: left !important;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  @media screen and (min-width: $desktop) {
    //max-height: 800px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.list {
  list-style: disc;
  list-style-position: inside;

  &__item {
    margin-bottom: 16px;
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  margin-top: 30px;

  @media screen and (min-width: $desktop) {
    flex-basis: 500px;
    flex-shrink: 1;
    margin-left: 40px;
    margin-top: 0;
    padding: 28px 30px 40px;

    .button {
      min-width: auto;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: auto;
      font-size: 18px;
      line-height: 23px;
      font-weight: bold;
      align-self: flex-end;
    }
  }
}

.row {
  position: relative;
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;

  & > * {
    margin-bottom: 14px;
    flex: 1;
  }

  @media screen and (min-width: $desktop) {
    flex-direction: row;
    flex-wrap: nowrap;

    & > *:not(:last-child) {
      margin-right: 40px;
    }

    & > * {
      margin-bottom: 20px;
    }
  }

  &__inline {
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (min-width: $desktop) {
      flex-wrap: nowrap;
    }

    & > * {
      width: calc((100% - 25px) / 2);
      min-width: 120px;

      &:nth-child(odd) {
        margin-right: 25px;
      }

      @media screen and (min-width: $tablet) {
        &:nth-child(2) {
          margin-right: 25px;
        }
      }

      @media screen and (min-width: $desktop) {
        width: auto;

        &:not(:last-child) {
          margin-right: 25px;
        }

        &:nth-child(3) {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 40px;
        }
      }
    }
  }

  .count {
    position: absolute;
    bottom: 20px;
    right: 25px;
    font-size: 14px;
    color: rgba(63, 64, 65, 0.3);
  }

  .textarea {
    margin-right: 0 !important;
  }
}


.details {
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;

  @media screen and (min-width: $desktop) {
    padding: 23px 30px;
    margin-bottom: 0;
  }
}

.images {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;

  @media screen and (min-width: $desktop) {
    flex-basis: 260px;
    flex-shrink: 0;
    padding: 26px 30px 28px;
  }
}

.sales {
  flex: 1;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  padding: 20px;
  margin-top: 30px;

  @media screen and (min-width: $desktop) {
    padding: 20px 28px 22px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .checkbox {
    margin-bottom: 30px;
  }

  .field {
    margin-bottom: 14px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 20px;
    }
  }
}

.subtitle {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  font-style: italic;
}

.button {
  align-self: flex-end;
  min-width: 110px;

  @media screen and (min-width: $desktop) {
    min-width: 160px !important;
  }
}

.root {
  position: relative;
  align-items: center;
  padding-left: 20px !important;
  padding-right: 20px !important;
  min-height: 70px;
  background: #fff !important;
  z-index: 1;
}

.dot {
  background-color: $primary-color !important;
  opacity: .3;

  &__active {
    opacity: 1;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -11px 6px;

  &__item {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    margin: 0 10px 20px;
    object-fit: cover;
    object-position: center;
  }

  &__edit {
    margin-top: auto;
    align-self: flex-end;
  }
}

.aside {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $desktop) {
    margin-left: 37px;
    //flex-basis: 400px;
    flex-shrink: 0;
    width: 390px;
  }
}

.cost {
  margin-left: auto;
  font-size: 13px;
  line-height: 20px;
  color: $primary-color;
}

.cost_title {
  font-size: 15px;
}

.stepper {
  position: relative;
  background-color: #fff;
  z-index: 1;

  &__wrapper {
    background-color: #fff;
    position: relative;

    width: 100%;
  }

  // margin: 0 -20px;

  &__shadow {
    position: absolute;
    content: '';
    top: 0;
    left: 9px;
    right: 9px;
    height: 50px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.12);
  }
}

.dialog {
  color: red;

  .MuiPaper-root {
    max-width: 100%;
  }
}

// Master Confirmation Modal
.modal_content {
  max-width: 600px;
}

.modal_footer {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
}
