@import 'src/styles/variables';

.footer{
    display: flex;
    justify-content: flex-end;
    button{
        margin-left: 15px;
    }
}
.content{
    display: flex;
    justify-content: center;
    margin: 40px 0;
    text-align: center;
    .name{
        color: $primary-color;
        font-weight: 800;
        margin: 0 5px;
    }
}
