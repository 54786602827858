@import '../../../styles/variables';

.filter {
    margin-bottom: 20px;

    input {
        padding: 11px 35px 11px 7px !important;
    }

    ::placeholder {
        font-style: italic;
        font-size: 14px;
        line-height: 28px;
        color: $font-color;
    }
}

.select {
    padding: 11px 35px 11px 7px !important;
    font-style: italic;
    margin-bottom: 15px;
    font-size: 15px !important;

    &:last-child {
        margin-bottom: 30px;
    }
}

.arrow {
    position: absolute;
    top: 23px;
    right: 22px;
    z-index: 2;
}

.icon path {
    fill: $primary-color;
}
