@import 'src/styles/variables';


.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .row {
    display: flex;
    flex-direction: column;
    margin: 0 -16px 10px -16px;
    @media screen and(min-width: $mobile) {
      flex-direction: row;
    }

    &>div {
      flex: 1;
      padding: 0 16px;
    }

    .phone_field {
      z-index: 2;
    }
  }
}
