@import 'src/styles/variables';
@import 'src/styles/mixins';
.message_letter {
  display: flex;
  flex-grow: 1;
  .ql-editor{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 10px;
    padding: 0;

    font-family: $font-Sen, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 34px;
    color: $font-color;
    @include with-scroll;
  }
}
#contact-tool{
  .ql-container{
    word-break: break-all;
    height: 335px;
    //overflow-y: auto;
  }
  @media screen and (max-width: $mobile){
    .ql-container{
      word-break: break-all;
      height: 335px;
      overflow-y: auto;
    }
    .ql-toolbar {
      overflow-x: auto;
      display: flex ;
      margin-right: 100px ;
      .ql-formats{
        display: flex;
      }
    }
  }
}

