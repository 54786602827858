@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  margin-top: 1rem;

  .title {
    margin-top: 0;
    border-bottom: 1px solid #e7e7e7;
    text-align: left;
    padding-bottom: 10px;


    @media screen and (max-width: $desktop) {
      margin-bottom: 20px;
      padding-bottom: 0;

    }
  }

  .order {
    @media screen and (min-width: $desktop) {
      padding-bottom: 50px;

      &:nth-child(2) {
        padding-top: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    & > *:last-child {
      margin-bottom: 3rem;
    }
  }

  .offers__title {
    margin-top: 50px;
  }

  .button {
    @include reset-btn;
    padding: 13px 15px;
    border-radius: 35px;
    font-size: 18px;
    line-height: 24px;
    transition: background-color 0.2s ease-in-out;

    &.danger {
      background-color: $error-color;

      @media screen and (min-width: $desktop) {
        margin-left: auto;
      }

      &:hover {
        background-color: darken($error-color, 5);
      }
    }

    &.success {
      margin-left: 15px;
      font-weight: bold;
      color: white;
      background-color: $success-color;

      &:hover {
        background-color: darken($success-color, 5);
      }
    }

    &.fixed {
      min-width: 130px;
    }
  }
}

.container {
  padding: 10px 30px;
}

.notification {
  margin-bottom: 35px;
}
