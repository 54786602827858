@import 'src/styles/variables';
@import 'src/styles/mixins';

.submenu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 !important;
  overflow-y: auto;

  &__wrapper {
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    border-top: 1px solid #e7e7e7;
    z-index: 4;
    max-width: 310px;

    @media screen and (max-width: $desktop - 1) {
      top: 0;
    }

    @media screen and (min-width: $desktop) {
      max-width: none;
    }

    .content {
      position: relative;
      height: 100vh;
      background-color: #fff;
      z-index: 1;

      @media screen and (min-width: $desktop) {
        padding: 23px 0;
        height: auto;
      }
    }

    &::after {
      position: absolute;
      display: none;
      content: '';
      left: 36px;
      right: 36px;
      height: 70px;
      bottom: 0;
      margin: 0 auto;
      box-shadow: 0 4px 60px rgba(0, 0, 0, 0.1);
      background-color: white;
      z-index: 0;

      @media screen and (min-width: $desktop) {
        height: 70px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    flex-direction: row;
    justify-content: space-between;
    overflow-y: hidden;
  }

  .popular_block {
    @media screen and (min-width: $desktop) {
      max-width: 300px;
    }

    .item {
      display: none;
      font-family: $font-Muli;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      color: inherit !important;

      @media screen and (min-width: $desktop) {
        display: inline-block;
        text-align: left;
        margin: 5px 0;
        padding: 2px 0 !important;
      }

      &:hover {
        color: var(--primary-color, $primary-color) !important;
      }

      &__last,
      &.last {
        width: 100%;
        display: flex;
        padding: 18px 20px;
        border-bottom: 1px solid #e7e7e7;
        font-family: $font-Sen;
        font-size: 20px;
        line-height: 24px;
        font-weight: normal;
        color: inherit;

        &:first-child {
          border-top: 1px solid #e7e7e7;
        }

        @media screen and (min-width: $desktop) {
          font-family: $font-Muli;
          font-size: 16px;
          padding: 5px 0;
          margin: 5px 0;
          border-bottom: 0;
          font-weight: bold;
        }

        &:hover {
          color: var(--primary-color, $primary-color) !important;
        }
      }
    }

    .item__wrapper {
      display: flex;
      align-items: center;
      margin-top: auto;

      .item {
        border: 0;

        &.last:first-child {
          border-top: 0;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @include media-up($desktop) {
            font-size: 16px;
            color: var(--primary-color, $primary-color) !important;
            margin-top: 0.5rem;
          }
        }
      }

      .extend {
        align-self: stretch;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        border-left: 1px solid #e7e7e7;

        @media screen and (min-width: $desktop) {
          display: none;
        }
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;

    &__wrapper {
      overflow-y: auto;
      @media screen and (min-width: $desktop) {
        display: flex;
        flex-grow: 1;
      }
    }

    .popular_block {
      border-top: 1px solid #e7e7e7;
      display: flex;
      flex-direction: column;

      &.last {
        .popular_block {
          border-bottom: 1px solid #e7e7e7;

          @media screen and (min-width: $desktop) {
            border-bottom: 0;
          }
        }
      }

      &:last-child:not(:first-child) {
        border-bottom: 1px solid #e7e7e7;

        @media screen and (min-width: $desktop) {
          border-bottom: 0;
        }
      }

      @media screen and (min-width: $desktop) {
        border-top: 0;
      }
    }

    @media screen and (min-width: $desktop) {
      padding: 0 50px;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }

    .sub_header {
      display: none;
      margin-bottom: 15px;
      font-family: $font-Muli;
      font-weight: 900;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      &::before {
        width: 6px;
        height: 6px;
        background-color: var(--primary-color, $primary-color);
        border-radius: 50%;
        content: '';
        margin-right: 0.5rem;
      }

      // color: $primary-color;

      @media screen and (min-width: $desktop) {
        display: flex;
        align-items: center;
      }
    }

    @media screen and (min-width: $desktop) {
      .row {
        display: flex;

        & > * {
          flex: 1;
        }
      }
    }

    @media screen and (min-width: $desktop) {
      &.main {
        flex-basis: 385px;
        border-right: 1px solid #e7e7e7;
      }

      &:not(.main) {
        flex-grow: 1;
      }
    }
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.title_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px 0;
  border-bottom: 1px solid #e7e7e7;

  &.last {
    padding-bottom: 0;
  }

  @media screen and (min-width: $desktop) {
    border-bottom: 0;
  }

  &.first {
    border-top: 1px solid #e7e7e7;

    @media screen and (min-width: $desktop) {
      border-top: 0;
    }
  }

  .title {
    font-family: $font-Sen;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit !important;
    padding: 0 20px !important;

    &:hover {
      color: var(--primary-color, $primary-color) !important;
    }

    @media screen and (min-width: $desktop) {
      padding: 0 !important;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 6px;
    }
  }

  &.main {
    margin-bottom: 60px;

    @media screen and (min-width: $desktop) {
      display: none;
    }

    .title {
      font-weight: 700;
      color: var(--primary-color, $primary-color) !important;
    }
  }

  @media screen and (min-width: $desktop) {
    min-height: 75px;
  }
}

.subtitle {
  display: none;

  @media screen and (min-width: $desktop) {
    display: inline-block;
    font-family: $font-Muli;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    font-weight: 700;
    color: rgba(63, 64, 65, 0.4);
    margin-bottom: 1.3rem;
  }

  &.default {
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
  }

  @include media-up($desktop) {
    &.dot {
      display: flex;
      align-items: center;
      color: $font-color;

      &::before {
        width: 6px;
        height: 6px;
        background-color: var(--primary-color, $primary-color);
        border-radius: 50%;
        content: '';
        margin-right: 0.5rem;
      }
    }
  }
}

.menu_item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-width: 120px;
  color: inherit !important;
  text-decoration: none;
  font-family: $font-Muli;
  font-weight: bold !important;
  font-size: 20px;
  transition: color 0.2s ease-in-out;
  will-change: color;
  border-bottom: 1px solid #e7e7e7;
  border-top: 2px solid transparent;

  &:hover {
    color: var(--primary-color, $primary-color) !important;
  }

  &.active {
    color: var(--primary-color, $primary-color) !important;

    @media screen and (min-width: $desktop) {
      border-bottom: 2px solid var(--primary-color, $primary-color);
    }
  }
}

.icon {
  width: 26px;
  height: 26px;
  margin: 5px;

  &__counter {
    color: var(--primary-color, $primary-color);
    font-weight: 700;
    margin-top: -20px;
    position: relative;
    right: -55px;

    &.mobile {
      margin-top: 10px;
      font-size: 14px;
      right: 0;
    }
  }
}

.tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 310px;
  height: 100vh;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  z-index: 1;

  &.active {
    transform: translateX(0);
  }

  &__list {
    overflow-y: auto;
    max-height: calc(100vh - 80px);

    & > * {
      border-top: 1px solid #e7e7e7;
      display: flex;

      &:last-child {
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    padding: 18px 20px !important;
    text-align: left;
    text-decoration: none;
    color: inherit;
    font-family: $font-Sen;

    .text {
      @include media-down($desktop) {
        @include ellipsis(calc(100% - 42px));
      }
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    margin-right: 20px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    flex-shrink: 0;
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.arrow {
  position: absolute;
  top: 22px;
  left: 9px;
  padding: 10px 15px;

  &__icon {
    transform: rotateY(180deg);

    path {
      fill: var(--primary-color, $primary-color);
    }
  }
}

.navIcon {
  margin-right: 20px;
  width: 24px;
  height: 24px;
}

.linkIcon {
  position: relative;
  top: -1rem;

  @include media-down($desktop) {
    display: none;
  }
}
