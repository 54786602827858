@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.container {
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  padding: 0 16px;

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    padding: 0 16px;
    flex-basis: calc(100% / 2);

    &:nth-child(2n - 1) {
      justify-content: flex-start;
    }

    &:nth-child(2n) {
      justify-content: flex-end;
    }
  }

  @media screen and (min-width: $desktop) {
    padding: 0 16px;
    flex-basis: calc(100% / 3);

    &:nth-child(3n - 2) {
      justify-content: flex-start;
    }

    &:nth-child(3n) {
      justify-content: flex-end;
    }
  }

  .item {
    width: 100%;
    height: 218px;
    display: flex;
    text-align: center;
    margin-bottom: 37px;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-position: 50% 50%;
    background-color: #6b88a4;
    will-change: box-shadow;
    transition: box-shadow 0.2s ease-in-out;
    font-size: 0;
    filter: saturate(0.5);

    &::before {
      content: attr(data-text);
      color: #fff;
      font-size: 24px;
      font-weight: 900;
      position: relative;
      word-break: break-word;
      font-family: $font-Mulish;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
    }

    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0 / 30%, 0.2);
    }

    @media screen and (min-width: $tablet) {
      width: 100%;
      max-width: 390px;
      height: 218px;
    }
  }
}
