@import 'src/styles/variables';
@import 'src/styles/mixins';

.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    max-width: 189px;
    max-height: 183px;
    width: 189px;
    height: 183px;
    border-radius: 10px;
    object-fit: cover;
  }

  .text {
    font-family: $font-Muli;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 30px;

    &__artist {
      color: $primary-color
    }

    &__title {
      font-weight: bold;
      word-break: break-word;
    }

    &__price {
      font-weight: bold;
    }
  }
}

.modal_body {
  padding: 28px 20px;
  overflow-y: auto;
  @include with-scroll;
}

.form {
  margin-top: 25px;
  width: 100%;

  @include media-up($desktop) {
    width: 40%;
  }


  .error {
    bottom: 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.step_buttons {
  button {
    margin: 0 5px 0 0;

    &:last-child {
      margin: 0 0 0 5px;
    }
  }
}
