@import '../../../styles/variables';

.root {
  height: 100%;
  width: 100%;

  &.canEdit:hover .dropzone {
    opacity: 1;
  }
}

.preview {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  background-color: $border-color;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.title {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 12px;
  color: $outline-color;
}

.dropzone {
  position: relative;
  border: 1px dashed $border-color;
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: opacity 0.2s ease-in;
  will-change: opacity;

  & > svg {
    margin-bottom: 1.5rem;
  }

  &:hover {
    border-color: $primary-color;
  }

  &.hasImage {
    opacity: 0;
    background-color: rgba($font-color, 0.8);

    .title {
      color: white;
    }

    .description {
      color: white;
    }
  }
}

.link {
  color: $primary-color;
  font-weight: 800;
}

.control_buttons {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}
