@import 'src/styles/variables';

.section {
  padding: 23px 30px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 34px;
    text-align: left;

    @media screen and (min-width: $desktop) {
        margin-bottom: 32px;
    }
  }

  .form {
    &__field {
      margin-bottom: 14px;

      @media screen and (min-width: $desktop) {
          margin-bottom: 20px;
      }
    }

    &__row {

      &:not(:last-child) {
          margin-bottom: 14px;
      }

      @media screen and (min-width: $desktop) {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &>* {
          flex: 1;

          &:not(:last-child) {
              margin-right: 40px;
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      @media screen and (max-width: $desktop - 1) {
        & > * {
            flex: 1;
        }
      }

      @media screen and (min-width: $desktop) {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__button {
      min-width: auto;
      max-width: 300px;

      &:not(:last-child) {
          margin-right: 20px;
      }

      .primary {
        font-weight: 800;

        @media screen and (min-width: $desktop) {
            font-weight: bold;
        }
      }

      @media screen and (min-width: $desktop) {
        max-width: none;
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }
}
