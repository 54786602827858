@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed $border-color;
  border-radius: 5px;
  width: 100%;
  min-height: 290px;
  font-size: 24px;
  line-height: 28px;
  color: $font-color;
  font-family: $font-Sen;
  font-weight: 700;
  margin-bottom: 50px;

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    color: darken($primary-color, 5%);
    background-color: darken(#fff, 5%);
  }
}

.icon {
  width: 85px;
  height: 85px;
  color: $primary-color;
}
