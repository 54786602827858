@import '../../../styles/variables';
@import '../../../styles/mixins';

.blockquote {
  display: block;
  position: relative;
  font-size: 20px;
  line-height: 30px;
  padding: 3rem 0;
  text-align: center;

  &_disabled {
    display: none;
  }

  &::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: absolute;
    color: $border-color;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    width: 100%;
    height: 100%;

    @include media-up($desktop) {
      width: 50%;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 80px;
    line-height: 0.5;
    color: $border-color;
    padding: 0 1rem;
    content: '\201C';
    background-color: #fff;
  }
}
