@import '../../../styles/variables';

.artist {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;

  &__card {
    display: block;
    height: 100%;
  }

  &__wrapper_empty {
    position: relative;
    width: 100%;
    flex-shrink: 0;
    flex-basis: 150px;
    height: 200px;
    border-radius: 10px;
    background-color: #c4c4c4;

    @media screen and (min-width: $desktop) {
      flex-basis: 280px;
      height: 380px;
    }
  }

  &__wrapper {
    position: relative;
    flex-shrink: 0;
    height: auto;
    border-radius: 10px;
    background-color: #c4c4c4;
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;
    will-change: box-shadow;
    min-height: 400px;

    .artist__card {
      min-height: 400px;

      & > img {
        min-height: 400px;
      }
    }

    @media screen and (min-width: 700px) {
      &.fluid {
        max-width: 280px;
        min-height: 380px;
        flex-shrink: 1;
      }
    }

    &.empty {
      background-color: #fff;
      border: 1px solid #e7e7e7;

      &:after {
        display: none;
      }
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.85) 100%
      );
      z-index: 1;
    }

    @media screen and (min-width: 700px) {
      height: 380px;
    }

    &:not(.empty):hover {
      box-shadow: 0 4px 8px 0 rgba(black, 0.3);
    }
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px 20px;
    background-color: transparent;
    z-index: 2;
    color: white;

    @media screen and (min-width: $desktop) {
      padding: 0 20px 20px;
    }

    .country {
      font-size: 11px;
      line-height: 16px;
      font-weight: 600;
      color: white;

      @media screen and (min-width: $desktop) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__name {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.artwork {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  font-size: 0;
  border: 1px solid #fff;
  background-color: #e7e7e7;

  &__wrapper {
    position: relative;
    transition: transform 0.2s ease-in-out;
    will-change: transform;

    &:hover {
      transform: scale(1.08);
      cursor: pointer;
    }

    .count {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid #fff;
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      color: white;
      background: linear-gradient(
        0deg,
        rgba(128, 107, 255, 0.6),
        rgba(128, 107, 255, 0.6)
      );
      z-index: 1;
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    height: 70px;
    margin-top: 8px;
    position: relative;
    z-index: 2;

    & > * {
      height: 100%;
      flex: 1;
      max-width: 70px;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    @media screen and (min-width: $desktop) {
      margin-top: 11px;
      max-width: none;
    }
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.paper {
  border-radius: 10px !important;
}
