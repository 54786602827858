@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  color: #fff;
  height: 500px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @include media-up($desktop) {
    height: 600px;
  }

  .content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 6rem;

    @include media-down($tablet) {
      flex-direction: column-reverse;
    }

    @include media-up($tablet) {
      align-items: flex-start;
    }

    @include media-up($desktop) {
      padding-bottom: 4rem;
    }
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
  }
}

.main {
  position: relative;
  text-align: center;
  z-index: 3;
  margin-top: auto;
  display: inline-block;

  @include media-up($tablet) {
    text-align: left;
  }
}

.title {
  font-size: 30px;
  line-height: 38px;
  font-family: $font-Sen;
  font-weight: 800;

  @include media-up($tablet) {
    max-width: 550px;
    font-size: 44px;
    line-height: 58px;
  }
}

.link {
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  min-width: 169px;
  font-size: 16px;
  font-weight: 700;
  color: $font-color;
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid $border-color;

  @include media-up($tablet) {
    font-size: 18px;
    border-radius: 40px;
  }
}

.footer {
  position: relative;
  z-index: 3;
  margin-top: 2rem;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @include media-up($tablet) {
    margin-top: auto;
  }
}

.label {
  font-size: 14px;
  font-style: italic;

  @include media-up($tablet) {
    font-size: 16px;
  }
}

.name {
  font-size: 16px;
  font-weight: 600;

  @include media-up($tablet) {
    font-size: 20px;
  }

  b {
    font-weight: 900;
  }
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.dots {
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);
  z-index: 2;

  @include media-up($tablet) {
    bottom: 30px;
  }

  & > * {
    display: inline-flex;
    flex-shrink: 0;
  }

  & > *:not(:last-child) {
    margin-right: 12px;

    @include media-up($tablet) {
      margin-right: 18px;
    }
  }
}

.dot {
  width: 8px;
  height: 8px;
  font-size: 0;
  display: inline-flex;
  background-color: rgba(#fff, 0.5);
  border-radius: 50%;

  &.active {
    background-color: #fff;
  }

  @include media-up($tablet) {
    width: 12px;
    height: 12px;
  }
}

.buttons {
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  color: #fff;
  max-width: 1280px;
  padding: 0 20px;
  margin-right: auto;
  margin-left: auto;
}

.button {
  color: #fff;

  @include media-down($tablet) {
    display: none;
  }

  &.disabled {
    display: none;
  }
}

.icon {
  &.reverse {
    transform: rotate(180deg);
  }
}
