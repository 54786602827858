.editor {
  h1 {
    padding: 10px 0!important;
    font-size: 28px!important;
    font-weight: bold!important;

    span {
      font-size: 28px!important;
      font-weight: bold!important;
    };
  }

  h2 {
    padding: 10px 0!important;
    font-size: 24px!important;
    font-weight: bold!important;

    span {
      font-size: 24px!important;
      font-weight: bold!important;
    };
  }

  h3 {
    padding: 10px 0!important;
    font-size: 22px!important;
    font-weight: bold!important;

    span {
      font-size: 22px!important;
      font-weight: bold!important;
    };
  }

  h4 {
    padding: 10px 0!important;
    font-size: 20px!important;
    font-weight: bold!important;

    span {
      font-size: 20px!important;
      font-weight: bold!important;
    };
  }

  p {
    font-size: 16px!important;
  }

  p > img {
    width: 200px;
    padding: 5px;
  }

}
