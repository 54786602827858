@import 'src/styles/variables';

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $font-Sen;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 1rem;
  color: $primary-color;
  border-radius: 3rem;
  height: 3rem;
  border: 1px solid $primary-color;
  text-transform: uppercase;
  max-width: 100%;
  cursor: pointer;
}

.content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.deleteBtn {
  margin-left: 1rem;
}
