@import '../../../styles/variables';

$card-width: 290px;
$card-height: 390px;

.image {
  height: $card-height;
  width: $card-width;
  object-fit: cover;
  border-radius: 10px;
  background-color: #c4c4c4;
}

.artist {
  padding: 5px;
  margin-top: 10px;
  width: $card-width;
  margin-left: auto;
  margin-right: auto;

  &__wrapper {
    position: relative;
    height: $card-height;
    width: $card-width;

    &::after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.85) 100%
      );
      z-index: 1;
      border-radius: 0 0 10px 10px;
    }
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    padding: 0 20px 20px;
    z-index: 2;
    color: white;

    @media screen and (min-width: $desktop) {
      padding: 0 20px 20px;
    }

    .country {
      font-size: 11px;
      line-height: 16px;
      font-weight: 600;
      color: white;
      @media screen and (min-width: $desktop) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__name {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    color: white;
  }
}

.artwork {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  font-size: 0;
  border: 1px solid #fff;
  background-color: #e7e7e7;
  object-fit: cover;

  &__wrapper {
    position: relative;
    transition: transform 0.2s ease-in-out;
    will-change: transform;

    &:hover {
      transform: scale(1.08);
    }

    .count {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid #fff;
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      color: white;
      background: linear-gradient(
        0deg,
        rgba(128, 107, 255, 0.6),
        rgba(128, 107, 255, 0.6)
      );
      z-index: 1;
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-height: 19.444444%;
    height: 100%;
    width: 100%;
    margin-top: 8px;

    & > * {
      flex-basis: 30%;
      height: 100%;
    }

    @media screen and (min-width: $desktop) {
      margin-top: 11px;
      max-width: none;
    }

    &.notfull {
      justify-content: flex-start;

      & > *:not(:last-child) {
        margin-right: 8px;

        @media screen and (min-width: $desktop) {
          margin-right: 15px;
        }
      }
    }
  }
}

.footer {
  padding: 20px 0;
  text-align: center;
}
