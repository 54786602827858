@import '../../styles/variables';

.container {
    text-align: center;
}

.preview {
    width: 700px;
    margin: 0 auto;

    &__wrapper {
        position: relative;

        @media screen and (min-width: $desktop) {
            &__artist{
                top: -40px;
            }
            margin-bottom: 38px;
        }
    }
    &__artist{
        @media screen and (min-width: $desktop) {
            top: -40px;
        }
    }

}

.blur {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

    @media screen and (min-width: $desktop) {
        height: 170px;
    }
}

.button {
    position: relative;
    z-index: 1;
}
