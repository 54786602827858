@import 'src/styles/variables';
@import 'src/styles/mixins';

.container_additional {
  .form_wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @include media-up($desktop) {
      flex-direction: row;
      justify-content: space-between;
    }

    .form_left {
      width: 100%;
      min-width: 280px;
      padding: 30px;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 15px;
      margin-right: 40px;

      @include media-up($desktop) {
        max-width: 400px;
        align-self: flex-start;
      }

      .methods {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .method_selector {
          label {
            height: 100px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e7e7e7;

            &:last-child {
              border-bottom: 0;
            }
          }
        }

        .icon {
          filter: grayscale(100%);
          cursor: pointer;

          &__active_icon {
            filter: none;
          }

          &__disabled {
            cursor: auto;
          }

          &__klarna_icon {
            height: 35px;
          }
        }

        .method {
          //width: 340px;
          //height: 121px;
          //display: flex;
          //flex-direction: row;
          //align-items: center;
          //border-bottom: 1px solid #E7E7E7;
        }
      }

      @media only screen and (max-width: $desktop) {
        width: 100%;
        margin-bottom: 20px;
        padding: 20px;
        .methods {
          .method {
            width: 100%;
            justify-content: flex-start;
          }
        }
      }
    }

    .form_right {
      width: 100%;
      padding: 30px;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 15px;

      .field {
        margin-bottom: 20px;

        .label {
          padding-left: 5px;
          width: 224px;
          height: 40px;
          font-family: $font-Muli;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 28px;
          display: flex;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          height: 50px;
          padding: 20px 15px;
          border: 1px solid #e7e7e7;
          box-sizing: border-box;
          border-radius: 5px;
          font-size: 16px;
          line-height: 28px;
          font-family: $font-Muli;
        }
      }

      @media only screen and (max-width: $desktop) {
        width: 100%;
        padding: 20px;
        .field {
          width: 100%;

          .label {
            width: auto;
          }

          input {
            width: 100%;
          }
        }
      }
    }
  }
}

.title {
  font-family: $font-Sen;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
}
