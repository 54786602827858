@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  border: 1px solid $border-color;
  border-radius: 1.5rem;
  background-color: #fff;
  padding: 2rem 0;
  &_hide {
    display: none;
  }

  @include media-up($desktop) {
    padding: 3rem 0;
  }

  .button {
    min-width: 160px;
    align-self: flex-end;
    margin-bottom: 15px;
  }

  .title {
    font-size: 28px;
    line-height: 34px;
    font-family: $font-Sen;
    color: $font-color;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1.5rem;
    margin-left: 2rem;

    @include media-up($desktop) {
      margin-left: 3rem;
    }
  }

  .groupTitle {
    font-size: 18px;
    font-weight: 700;
    color: $font-color;
    margin-top: 0;
    margin-bottom: 1.5rem;
    &_updating {
      margin: 0;
    }
  }

  .list {
    @include media-down($desktop) {
      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    @include media-up($desktop) {
      display: flex;
      margin: 0 1rem;
      flex: 1;
    }
  }

  .group {
    border: 1px solid $border-color;
    padding: 2rem;
    border-radius: 1.5rem;
    margin: 0 2rem;
    width: calc(100% - 4rem);
    display: flex;
    flex-direction: column;

    .content_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (min-width: $mobile) {
        margin-top: 0;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      .pm_info {
        display: flex;
        align-items: center;
      }
    }
  }

  .footer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    @media screen and (min-width: $mobile) {
      margin-top: 0;
      flex-wrap: nowrap;
      width: 0;
      justify-content: flex-end;
    }
  }

  .icon {
    margin-right: 1.3rem;
  }
}

.modal {
  display: flex;
  justify-content: center;

  &_width {
    max-width: 800px!important;
  }

  &_content {
    padding: 20px 0 30px 0;
  }

  &_footer {
    padding: 12px 40px;
  }

  .footer {
    .button {
      min-width: 110px;
    }
  }
}
