@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  --trasition-prop: backgroud-color;
  font-family: inherit;
  font-size: 16px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border-radius: 50px;
  min-width: 60px;
  height: 60px;
  //transition: var(--trasition-prop, background-color) 0.4s ease-out;

  &:disabled {
    cursor: not-allowed;
    background-color: $disabled-color;
  }

  &.sm {
    height: 50px;
    min-width: 50px;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 7px;
    }

    &:not(:last-child) {
      margin-right: 13px;
    }
  }

  &.xs {
    height: 30px;
    min-width: 30px;

    .icon:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  @include media-down($desktop) {
    height: 50px;
    min-width: 50px;
  }
}

.primary {
  background-color: $primary-color;
  color: #fff;
  font-weight: 800;

  &.fill {
    background-color: transparent;
    color: $primary-color;
  }

  &:hover:not(:disabled) {
    background-color: darken($primary-color, 5%);

    &.fill {
      background-color: transparent;
      color: darken($primary-color, 5%);
    }
  }
}

.secondary {
  border: 1px solid $border-color;

  &:hover{
    background-color: darken(white, 5%);
  }

  &.active {
    background-color: $primary-color;
    border-color: $primary-color;
    color: white;
  }
}

.link {
  --trasition-prop: color;

  &:hover,
  &:focus {
    color: $primary-color;
  }

  &:disabled {
    background-color: transparent;
    color: rgba($font-color, 0.4);
  }
}

.success {
  background-color: $success-color;
  color: #fff;
  font-weight: 800;

  &:hover:not(:disabled) {
    background-color: darken($success-color, 5%);

    &.fill {
      background-color: transparent;
      color: darken($success-color, 5%);
    }
  }
}

.danger {
  background-color: $error-color;
  color: #fff;
  font-weight: 800;

  &:hover:not(:disabled) {
    background-color: darken($error-color, 5%);

    &.fill {
      background-color: transparent;
      color: darken($error-color, 5%);
    }
  }
}

.warning {
  background-color: $pending-color;
  color: #fff;
  font-weight: 800;

  &:hover:not(:disabled) {
    background-color: darken($pending-color, 5%);

    &.fill {
      background-color: transparent;
      color: darken($pending-color, 5%);
    }
  }
}
