@import '../../styles/variables';
@import '../../styles/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media-up($desktop) {
    min-width: 505px;
  }
}
