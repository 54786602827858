@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
  list-style: none;

  &.horizontal {
    display: flex;
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }
}
