.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 960px) {
      flex-direction: column;

      .buttons_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .main_button{
        min-width: 100px;
        margin-left: 15px;
        padding: 18px;
        @media screen and (max-width: 750px){
            margin: 0;
            padding: 8px;
        }
    }
}
