@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  overflow: hidden;
  padding-top: 95px;

  @include media-down($desktop) {
    padding-top: 25px;
  }
}

.content_wrapper {
  @include media-down($tablet) {
    flex-wrap: wrap;
    min-height: 760px;
    overflow: hidden;
    height: auto;
  }
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;

  @include media-down($desktop) {
    height: 370px;
  }

  @include media-down($tablet) {
    flex-wrap: wrap;
    height: 710px;
  }

}

.slider_sections {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14%;
  padding: 20px;

  & > * {
    width: 430px;
  }

  @include media-down($desktop) {
    width: 380px;
  }

  @include media-down($tablet) {
    width: 320px;
    height: 410px;
    margin: 30px 0 0 0;
  }
}

.picture_container {
  position: relative;
  display: flex;

  @include media-down($desktop) {
    top: -30px;
  }
}

.girl_container {
  position: relative;
  width: 428px;
  height: 595px;
  top: -95px;
  z-index: 2;

  @include media-down($desktop) {
    width: 300px;
    height: 400px;
    top: 0;
  }

  @include media-down($tablet) {
    top: 20px;
    left: 170px;
    height: 280px;
  }
}

.quotes {
  position: relative;
  width: 300px;
  height: 240px;
  z-index: 1;
  left: -25%;
  top: -40px;

  @include media-down($orient-laptop) {
    width: 260px;
    height: 200px;
    left: -25%;
    top: 11px;
  }

  @include media-down($desktop) {
    width: 200px;
    height: 160px;
    top: 41px;
  }

  @include media-down($tablet) {
    top: 20px;
    left: -5%;
  }
}
