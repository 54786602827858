@import '../../../styles/variables';

.breadcrumbs {
  background-color: #f7f7f7;
  font-size: 16px;
  line-height: 20px;

  &.sm .list {
    min-height: 50px;
  }

  @media screen and (min-width: $desktop) {
    display: block;
  }

  .container {
    display: flex;
    align-items: center;
  }

  .list {
    display: none;
    align-items: center;
    min-height: 80px;

    @media screen and (min-width: $desktop) {
      display: flex;
    }
  }

  .item {
    display: inline-flex;
    align-items: center;

    max-width: 300px;

    &:last-child {
      font-weight: bold;
      color: #c7c7c7;
      max-width: 215px;

      .arrow {
        display: none;
      }
    }

    .link {
      white-space: pre;
      color: inherit;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }

  .back {
    display: inline-flex;
    min-height: 60px;
    align-items: center;

    @media screen and (min-width: $desktop) {
      display: none;
    }
  }

  .arrow {
    margin: 0 20px;

    path {
      fill: #c7c7c7;
    }
  }
}
