
.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiCircularProgress-colorPrimary {
        color: #806BFF !important;
    }    
    &.full{
        height: 60vh 
    }
}
