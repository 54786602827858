@import 'src/styles/variables';
@import 'src/styles/mixins';

.not_found {
  margin: 10% auto;
  width: 50%;
}

.wrapper {
  padding-bottom: 80px;

  @include media-up($desktop) {
    padding-bottom: 140px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.title_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  & > *:not(:last-child) {
    margin-bottom: 0.5em;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 0;
    word-break: break-word;
    font-family: $font-Sen;
  }

  .sub_title {
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    margin-top: 2px;
    line-height: 30px;
    color: #c7c7c7;

    @media screen and (max-width: $desktop) {
      font-size: 14px;
    }
  }
}

.content {
  @media screen and (min-width: $tablet) {
    max-width: 840px;
    margin: auto;
    padding: 0 20px;
  }
}

.editor {
  margin-top: 20px;
}

.ql_editor_content {
  h1 {
    font-size: 28px;
    font-weight: bold;
    padding: 10px 0;
  }

  h2 {
    font-size: 26px;
    font-weight: bold;
    padding: 10px 0;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
  }

  h4 {
    font-size: 22px;
    font-weight: bold;
    padding: 10px 0;
  }

  p {
    line-height: 32px;
    padding: 10px 0;
  }

  pre {
    all: initial;
    font-family: $font-Muli;
    font-size: 16px;
    line-height: 32px;
    color: $black-font;
  }

  a {
    color: $link-color;
    text-decoration: underline;
  }

  img {
    padding: 10px 0 10px 0 ;
    margin: 0 auto;
  }
}

.post_img_wrap {
  display: flex;
  width: 100%;
  padding-bottom: 40px;

  img {
    width: 100%;
    border-radius: 5px;
    max-height: 400px;
    object-fit: cover;
  }
}

.footer_sub_title {
  font-family: $font-Sen;
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  padding: 30px 0;
}

.related_posts_wrap {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  margin-bottom: 40px;

  &_one {
    justify-content: center;
  }

  @media screen and (max-width: $tablet) {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
  }

  .posts {
    flex-grow: 0;
    margin-right: 60px;
    @media screen and (max-width: 1270px) {
      margin-right: 20px;
    }

    @media screen and (max-width: 1200px) {
      margin: 10px auto;
    }

    @media screen and (min-width: 1200px) {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    cursor: pointer;
    &:hover {
      .featured_image {
        opacity: 0.5;
      }
    }
  }
}

.content {
  padding-bottom: 20px;
}

.artwork_wrap {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin-bottom: 120px;

  & > * {
    margin-bottom: 20px;
  }

  @include media-up($tablet) {
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
      width: calc((100% - 20px) / 2);

      &:nth-child(odd) {
        margin-right: 20px;
      }
    }
  }

  @include media-up($desktop) {
    & > * {
      width: calc((100% - 60px) / 4);
      flex-grow: 0;
      margin-bottom: 0;
    }

    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.list {
  @include media-down($desktop) {
    & > *:not(:last-child) {
      margin-bottom: 26px;
    }
  }

  @include media-up($tablet) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -13px;

    & > * {
      width: calc(50% - 26px);
      margin: 0 13px;
    }
  }

  @include media-up($orient-laptop) {
    margin: 0 -25px;
    justify-content: center;
    flex-wrap: nowrap;

    & > * {
      width: 378px;
      margin: 0 25px;
    }
  }
}

.keywordsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.keywordsWrapper > * {
  margin-right: 2rem;
}
