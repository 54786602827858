@import 'src/styles/variables';

.artist {
    &__wrapper_empty {
        position: relative;
        width: 100%;
        height: 210px;
        flex-shrink: 0;
        flex-basis: 155px;
        border-radius: 10px;
        background-color: #c4c4c4;

        @media screen and (max-width: $desktop - 1) {
            width: 100%;
            height: 380px;
            min-width: 280px;
            &.fluid {
                flex-basis: auto;
                height: 380px;
                flex-grow: 1;

                .artwork__list {
                    max-width: 240px;
                }
            }
        }

        @media screen and (min-width: $desktop) {
            &.fluid {
                flex-shrink: 1;
            }
        }

        @media screen and (min-width: $desktop) {
            flex-basis: 280px;
            min-height: 380px;
        }
    }

    &__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 20px 20px;
        background-color: transparent;
        z-index: 2;

        @media screen and (min-width: $desktop) {
            padding: 0 20px 20px;
        }
    }

    &__name {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 800;
        color: white;

        @media screen and (min-width: $desktop) {
            font-size: 24px;
            line-height: 30px;
        }
    }
}

.artwork {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;

    &__wrapper {
        position: relative;
        border-radius: 5px;
        transition: transform .2s ease-in-out;
        will-change: transform;
    }

    &__list {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        height: 70px;
        margin-top: 8px;

        &>* {
            height: 100%;
            flex: 1;
            max-width: 70px;

            &:not(:last-child) {
                margin-right: 15px;
            }
        }

        @media screen and (min-width: $desktop) {
            margin-top: 11px;
            max-width: none;
        }
    }
}
