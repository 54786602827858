@import 'src/styles/variables';
@import 'src/styles/mixins';

.verification_buttons {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;

  @include media-down($mobile) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
  }
}
.rating_wrapper {
  display: flex;
  align-items: center;
  margin-left: 30px;
  & > * {
    cursor: pointer;
    margin-right: 5px;
  }

  @include media-down($mobile) {
    margin-right: 30px;
  }
}

.wrapper {
  min-height: calc(100vh - 80px);
}

.row {
  margin-top: 25px;

  @include media-up($tablet) {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
    margin-bottom: 36px;
  }
}

.content {
  flex: 1 1;
  width: 100%;
  margin-top: 1.5rem;

  @media screen and (min-width: $desktop) {
    padding: 0;
    margin-right: 100px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 24px;
  font-family: $font-Sen;

  &.titleEdit {
    flex-direction: column;
  }

  @include media-up($desktop) {
    font-size: 30px;
    display: block;
    margin-bottom: 36px;
  }

  h1 {
    font-size: inherit;
    word-break: break-word;
  }

  @include media-up($desktop) {
    h1 {
      margin-top: 63px;
      line-height: inherit;
    }
  }
}

.description {
  font-size: 14px;
  font-family: $font-Sen;
  word-break: break-word;

  @media screen and (min-width: $tablet) {
    font-size: 40px;
    line-height: 48px;
    max-width: 100%;
  }
}

.thumbnail_text {
  font-size: 16px;

  @media screen and (max-width: $mobile) {
    font-size: 12px;
    width: 300px;
  }

  @media screen and (max-width: $iPhone5) {
    font-size: 10px;
    width: auto;
  }
}

.edit_profile_image {
  display: none;
  z-index: 2;
  position: absolute;
  right: 15px;
  top: 15px;

  width: 60px;
  text-align: center;

  font-weight: 600;
  font-family: $font-Sen;

  padding: 8px;
  background-color: #fff;

  border-radius: 8px;

  cursor: pointer;

  box-shadow: 1px 1px 5px $primary-color;

  &__save {
    display: block;
    box-shadow: 1px 1px 5px #fff;
    background-color: $primary-color;
    color: #fff;
  }
}

.image {
  max-height: 550px;
  object-fit: cover;
  object-position: center;
  min-height: 180px;
  border-radius: 15px;

  @media screen and (min-width: $desktop) {
    height: 100%;
  }

  &__wrapper {
    position: relative;
    border-radius: 20px;
    min-width: 280px;
    min-height: 460px;
    order: 0;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-content: center;

    @include media-up($tablet) {
      max-width: 370px;
      margin-left: 2rem;
    }

    @media screen and (max-width: $desktop) {
      width: 100%;
    }

    @media screen and (min-width: $desktop) {
      height: 470px;
      max-width: 50%;
      flex-grow: 0;
    }

    @media screen and (max-width: $mobile) {
      min-height: 200px;
    }
  }

  &__wrapper:hover &__upload {
    visibility: visible;
    opacity: 1;
  }

  &__wrapper:hover .edit_profile_image {
    display: block;
  }

  @media screen and (max-width: $desktop) {
    &__wrapper.edit &__upload {
      visibility: visible;
      opacity: 1;
    }
  }

  &__thumbnail {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 10px 5px 5px 5px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    background-color: #e7e7e7;
    box-shadow: 0 0 0 5px white;

    @media screen and (max-width: $mobile) {
      width: 50px;
      height: 50px;
    }

    @media screen and (max-width: $iPhone5) {
      width: 40px;
      height: 40px;
    }
  }

  &__upload {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color: white;

  &__button {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    font-family: $font-Sen;
    font-size: 16px;
    line-height: 20px;
    text-transform: unset;
    color: white;
    text-align: center;
    cursor: pointer;

    @media screen and (min-width: $desktop) {
      max-width: 280px;
      font-size: 16px;
      line-height: 26px;
    }

    @media screen and (max-width: $container) {
      .avatar__text {
        width: 270px;
        padding-top: 7px;
        font-size: 14px;

        @media screen and (max-width: $mobile) {
          font-size: 12px;
        }

        @media screen and (max-width: $iPhone5) {
          font-size: 10px;
        }
      }

      span {
        margin-top: 0 !important;
      }
    }

    span {
      margin-top: 13px;
    }

    input {
      display: none;
    }
  }

  &__icon {
    width: 80px;
    height: 80px;
    margin: 10px 5px 5px 5px;

    @media screen and (min-width: $desktop) {
      width: 110px;
      height: 110px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 16px;

    @media screen and (min-width: $desktop) {
      margin-left: 30px;
      margin-right: 30px;
    }

    @media screen and (max-width: $container) {
      bottom: 30px;
      font-size: 12px;
    }

    b {
      font-weight: 800;
    }
  }

  &__formats,
  &__dimensions,
  &__size {
    display: inline-flex;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 33%;
  }

  &__dimensions {
    position: absolute;
    left: 50%;
    bottom: 0;
    text-align: center;
    transform: translateX(-50%);
  }

  &__size {
    text-align: right;
  }
}

.edit {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (min-width: $desktop) {
    flex-direction: row;
  }

  &__box {
    padding: 20px 0;
    background-color: #f7f7f7;
    border-bottom: 2px solid $primary-color;
  }

  &__label {
    display: inline-flex;
    align-items: center;

    .icon {
      margin-right: 12px;
    }
  }
}

.discard {
  min-width: auto !important;
  padding: 13px 20px !important;
  background-color: #fff;
  font-weight: normal !important;
  margin-bottom: 10px;
  margin-top: 10px;

  @media screen and (min-width: $desktop) {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.primary {
  min-width: auto !important;
  padding: 13px 20px !important;

  @media screen and (min-width: $desktop) {
    margin-left: 20px;
  }
}

.text__input {
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
  margin-bottom: 6px;
}

.in__focus {
  text-decoration: underline;
}

.textarea__focus textarea {
  text-decoration: none !important;
}

.textarea__resize textarea {
  resize: none !important;
}

.mobile__edit {
  margin-left: auto;
  border: 0;
  padding: 7px;
  background-color: rgba(128, 107, 255, 0.3);
  border-radius: 5px;
  font-size: 0;

  @media screen and (min-width: $desktop) {
    display: none;
  }

  svg path {
    fill: $primary-color;
  }
}

.mobile__download {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  color: $primary-color;
  background-color: rgba(128, 107, 255, 0.3);
  padding: 7px;
  border-radius: 5px;

  svg {
    margin-right: 10px;

    path {
      fill: $primary-color;
    }
  }

  &:disabled {
    background-color: rgb(224, 224, 224);
    color: lighten($color: $font-color, $amount: 10);
    cursor: inherit;

    svg {
      path {
        fill: lighten($color: $font-color, $amount: 15);
      }
    }
  }

  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.control_buttons {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}

.tab {
  font-size: 20px;
}

// Tabs navigation

.tabs {
  position: relative;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 20px 0;

  .list {
    @include media-down($desktop) {
      width: 100%;
    }

    & > *:not(:last-child) {
      @include media-up($desktop) {
        margin-right: 34px;
      }
    }
  }

  .tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: $font-color;
    font-family: $font-Mulish;
    min-width: 50px;

    @include media-down($mobile) {
      min-width: 45px;
    }

    &:hover {
      color: $primary-color;
    }

    &.active {
      color: $primary-color;
      font-weight: 800;

      &::after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: $primary-color;
      }
    }
  }

  .text {
    font-size: 15px;
    display: none;

    @include media-up($desktop) {
      font-size: 18px;
    }

    @include media-up($desktop) {
      display: inline;
    }
  }

  .icon {
    width: 24px;
    height: 24px;

    @include media-up($desktop) {
      margin-right: 9px;
    }
  }
}

.buttonThumbnail {
  @include media-down($desktop) {
    display: none;
  }
}

.country {
  &.mobile {
    @include media-up($tablet) {
      display: none;
    }
  }

  &.desktop {
    margin-top: 2em;
    @include media-down($tablet) {
      display: none;
    }
  }
}

.button_wrap {
  margin-top: 40px;
}

