@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
  padding: 1em 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100px;
  width: 100%;
}

.link {
  color: $outline-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
}
