.switcher-with-modal {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between
}

.switcher-wrapper {  
  .MuiSwitch-root {
    width: 45px;
    height: 28px;
    padding: 0;
    box-shadow: 0 0 2px rgba(136, 136, 136, 1);
    border-radius: 20px;

    .MuiSwitch-switchBase {
      top: 2px;
      left: 1px;
      padding: 0;
      color: white;

      &:hover {
        background-color: rgba(115, 176, 244, 0);
        color: whitesmoke;
      }
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(20px);
      color: white;

      &:hover {
        background-color: rgba(115, 176, 244, 0);
        color: whitesmoke;
      }
    }

    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      opacity: 0.6;
      background-color: #73B0F4;
    }

    .MuiSwitch-track {
      background-color: white;
    }

    .MuiSwitch-thumb {
      width: 23px;
      height: 23px;
    }
  }
  .MuiTypography-body1 {
    font-size: 13px;
    padding-left: 5px;
    font-weight: 500;
    text-transform: initial;
  }
}
