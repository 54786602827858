@import '../../../../styles/variables';
@import '../../../../styles/tabs';

.tools {
  display: inline-flex;
  align-items: center;

  &::after {
    position: absolute;
    content: '';
    display: block;
    bottom: 0;
    height: 26px;
    left: 26px;
    right: 26px;
    background: #C4C4C4;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.8);
    z-index: 0;
  }

  path {
    transition: fill .2s ease-in-out;
    will-change: fill;
  }
}

.tool {
  padding: 9px 14px;
  border: 0;
  background-color: #3F4041;
  position: relative;
  z-index: 1;
  display: inline-flex;
  flex-shrink: 0;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  svg {
    width: 30px;
    height: 30px;
  }

  path {
    fill: white;
  }

  &:hover path {
    fill: $primary-color;
  }
}
