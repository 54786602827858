@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  &:not(:first-child) {
    margin-top: 50px;
  }
}

.title {
  font-size: 24px;
  font-weight: 800;
  font-family: $font-Sen;
  margin-top: 0;
  margin-bottom: 1.2rem;

  @include media-up($desktop) {
    margin-bottom: 2rem;
  }
}

.list {
  @include media-up($desktop) {
    display: flex;
    flex-wrap: wrap;
    margin: -1.5rem -2rem;
  }
}

.item {
  width: 100%;

  @include media-down($desktop) {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  @include media-up($desktop) {
    max-width: calc(100% / 3);
    padding: 1.5rem 2rem;
  }
}

.post {
  position: relative;
  height: 480px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: $desktop) {
    margin-bottom: 30px;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  padding: 18px 18px;

  @include media-up($desktop) {
    padding: 18px 15px;
  }
}

.label {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  font-family: $font-Sen;
  margin-bottom: 0.5rem;

  @include media-up($desktop) {
    margin-bottom: 1rem;
  }
}

.description {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 1.5rem;

  @include ellipsis-rows(3);
}

.author {
  display: flex;
  border-top: 1px solid $border-color;
  padding-top: 1.5rem;
}

.avatar {
  border-radius: 50%;
  background-color: $border-color;
  object-fit: cover;
  margin-right: 1.7rem;
}

.name {
  font-family: $font-Lato;
  font-weight: 700;
  font-size: 16px;
  color: $primary-color;
  display: block;
  margin-bottom: 0.5rem;
}

.time {
  font-weight: 400;
  font-size: 16px;
  font-family: $font-Lato;
  font-style: italic;
}

.notFound {
  margin-top: 3rem;
  text-align: center;
  font-size: 20px;
}
