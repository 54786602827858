@import './variables';

.font-content {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: $font-color;
}

.font-content-2 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 800;
  color: $font-color;
}

.font-title {
  font-size: 28px;
  line-height: 35px;
  font-weight: 800;
  color: $font-color;
}

.font-title-1 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  font-family: $font-Sen;
}

.font-title-2 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 800;
}

.font-caption {
  font-size: 12px;
  font-weight: 800;
  font-family: $font-Muli;
}

.font-caption-2 {
  font-size: 14px;
  line-height: 18px;
}
