@import 'src/styles/variables';

.description {
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e7e7e7;

  p {
    color: #c7c7c7;
  }

  @media screen and (min-width: $desktop) {
    font-size: 16px;
    line-height: 28px;
    margin-top: 5px;
    padding-bottom: 24px;
  }
}

.details {
  padding-top: 20px;

  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    label {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: #c7c7c7;
      text-transform: unset;
    }
  }

  .field {
    flex: 1;
    margin-bottom: 16px;
    max-width: 100%;

    @media screen and (min-width: $desktop) {
      &:not(:last-child) {
        padding-right: 20px;
      }
    }

    &:nth-child(1) {
      flex-basis: 260px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      flex-basis: 150px;
    }
  }

  .editing .field {
    display: flex;
  }
}
