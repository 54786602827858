.drawer-content {
  .MuiButton-root {
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
    //color: lighten(#707070, 10%);
    color: #707070;
    width: 1;
    padding: 0;
    right: 6px;
    //top: -7px;
    //margin-right: 10px;

    &:hover {
      background-color: transparent;
    }
  }

  .sign-in {
    background-color: #8cc57d;
    padding: 5px;
    border-radius: 2px;
    font-weight: 600;
  }

  .dashboard {
    background-color: #8cc57d;
    padding: 5px;
    border-radius: 2px;
    font-weight: 600;
    color: white !important;
  }

  .MuiDrawer-paper {
    background-color: white;
    width: 160px;
  }

  .link {
    //color: white;
    color: #707070;
    text-decoration: none;
    font-size: 18px;
  }
  .shopping-cart {
    display: flex;
    align-items: center;
    font-size: 20px;

    .icon-size {
      font-size: 20px;
    }

    .counter {
      font-size: 12px;
      margin-top: 8px;
    }
  }
}
