@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  margin-bottom: 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .label {
      font-size: 16px;
      line-height: 28px;
      font-weight: bold;
      color: inherit;
      text-transform: initial;
    }

    .dropdown {
    }

    .icon {
      flex-shrink: 0;
      transform: rotateZ(180deg);
      transform-origin: center;
      transition: transform 0.2s ease-in-out;

      &.expanded {
        transform: rotateZ(0);
      }
    }
  }

  .list {
    max-height: 500px;
    overflow-y: auto;

    @include with-scroll();

    &__wrapper {
      height: 0;
      padding-left: 8px;
      border-bottom: 1px solid #e7e7e7;
      overflow: hidden;

      &.expanded {
        height: auto;
        padding-bottom: 20px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      font-family: $font-Lato;
      font-size: 14px;
      line-height: 18px;

      &.checked {
        font-weight: 700;
      }

      &__checkbox {
        width: 100%;
      }
    }
  }

  .more {
    display: flex;
    align-items: center;
    padding: 8px 0;
    font-style: italic;
    font-family: $font-Lato;
  }

  .dots {
    position: relative;
    flex-shrink: 0;
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-left: 8px;
    margin-right: 16px;
    border-radius: 50%;
    background-color: $primary-color;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $primary-color;
    }

    &::after {
      right: -8px;
    }

    &::before {
      left: -8px;
    }
  }
}
