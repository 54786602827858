@import '../../../styles/variables';
@import '../../../styles/mixins';

.shipping_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .artwork_wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $desktop) {
      flex-direction: row;
      justify-content: space-between;
    }

    .info {
      display: flex;
      flex-direction: column;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 20px;
      width: 100%;

      .rate_wrapper {
        display: flex;
        margin-bottom: 10px;

        @include media-down($mobile) {
          flex-direction: column;
        }
      }

      .artwork_title_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;

        @include media-up($desktop) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }

      .artwork_title {
        font-family: $font-Sen;
        font-size: 28px;
        line-height: 34px;
        word-break: break-word;
        @media only screen and (max-width: $desktop) {
          width: 100%;
        }
      }

      .price {
        font-size: 28px;
        line-height: 35px;
        font-weight: 600;
        color: $primary-color;
        font-family: $font-Sen;
        @media screen and (max-width: $desktop) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .rate_warning {
        font-size: 15px;
        line-height: 20px;
        font-family: Mulish, serif;
        font-style: normal;
        font-weight: 600;
        color: $disabled-color;

        &_hiden {
          visibility: hidden;
        }
      }

      .shipping_selector {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        div {
          label {
            font-family: Mulish, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #3f4041;
            margin-bottom: 0;
            margin-left: -1px;
            margin-right: 10px;

            span {
              padding: 0 1px 3px 0;
              font-size: 15px;
              text-align: center;
            }

            span > span > input {
              margin: 0;
            }

            span > span > div > svg {
              color: $primary-color;
              font-size: 25px;
            }
          }
        }

        .radio {
          label > span {
            &:hover {
              background-color: transparent !important;
            }
          }
        }

        .radioLegend {
          color: $primary-color !important;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }

    .address_selector {
      display: flex;

      .address_form {
        height: auto;
        box-sizing: border-box;
        border-radius: 15px;
        display: flex;
        width: 100%;
        flex-direction: column;

        .title {
          font-family: $font-Sen;
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: 34px;
          color: $primary-color;
        }

        .autocomplete {
          padding: 10px 0 0 0;
        }
      }
    }

    .clear_delete_wrapper {
      display: flex;
      width: 100%;
      @media screen and(min-width: $mobile) {
        width: auto;
      }
    }

    .buttons_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;

      @media screen and (min-width: $mobile) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .button {
      padding: 17px 8px;
      font-size: 14px;
      line-height: 16px;
      background-color: $primary-color;
      border-radius: 50px;
      color: white;
      font-family: 'Sen', sans-serif;
      font-weight: 600;
      margin-top: 15px;
      width: 100%;
      transition: 400ms;

      @media screen and(min-width: $mobile) {
        font-size: 18px;
        padding: 18px 18px;
        width: 200px;
        line-height: 24px;
      }

      &:hover {
        background-color: darken($primary-color, 4%);
      }

      &:disabled {
        background-color: lighten(#e7e7e7, 5%);
        cursor: default;
        color: lighten(black, 50%);
      }

      &__load {
        @keyframes loadRate {
          0% {
            background-color: $primary-color;
          }
          50% {
            background-color: lighten($primary-color, 10%);
          }
          100% {
            background-color: $primary-color;
          }
        }

        animation-name: loadRate;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in;
      }

      &__cancel {
        color: black;
        background-color: white;
        border: 1px solid #e7e7e7;
        width: 100%;

        @media screen and(min-width: $mobile) {
          width: 120px;
          margin-left: 10px;
        }

        &:hover {
          background-color: #e7e7e7;
        }
      }

      &__delete {
        width: 100%;
        margin-left: 5px;
        background-color: $error-color;

        @media screen and(min-width: $mobile) {
          width: 120px;
          margin-left: 10px;
        }

        &:hover {
          background-color: darken($error-color, 10%);
        }
      }
    }

    .image_wrapper {
      width: 100%;
      display: inline-block;

      @include media-up($mobile) {
        width: 140px;
        height: 140px;
        margin-right: 1em;
      }

      @media only screen and (min-width: $tablet) {
        margin-right: 2em;
        margin-bottom: 0;
        width: 200px;
        height: 200px;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .small-button {
    line-height: 14px;
    font-size: 16px;
    padding: 5px;
  }

  .MuiIconButton-colorSecondary {
    color: $primary-color;
  }
}
