@import 'src/styles/variables';

.root {
  position: fixed;
  width: 100%;
  height: 30px;
  background-color: whitesmoke;
  padding-left: 20px;
  align-self: center;
  z-index: 3;
}
