@import 'src/styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (min-width: $tablet) {
        min-width: 200px;
        width: 200px;
        margin-right: 0;
    }
    margin-right: 20px;
    min-width: 140px;
    width: 140px;
    border-bottom: 1px solid #E7E7E7;
    justify-content: space-between;
    cursor: pointer;

    &__active{
        border-bottom: 1px solid $primary-color;
        .primary_image {
            opacity: .5;
        }
    }

    &:hover{
        .primary_image {
            opacity: .5;
        }
    }

    .primary_image {
        width: 100%;
        @media screen and (min-width: $tablet) {
            height: 200px;
        }
        height: 126px;
        object-fit: cover;
        margin-bottom: 20px;

        border-radius: 10px;
    }
    .title {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        display: block;
        padding-bottom: 10px;
        text-align: center;

        overflow: hidden;
        text-overflow: ellipsis;

        &__no_title{
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            display: block;
            padding-bottom: 10px;
        }
    }
    .username{
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: $primary-color;
        display: block;
        padding-bottom: 15px;
    }
    .footer {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 20px;
        align-items: flex-end;
        .artist_img{
            object-fit: cover;
            border-radius: 100%;
            height: 35px;
            width: 35px;
            &__no_img {
                height: 35px;
                width: 35px;
                background-color: #C7C7C7;
                border-radius: 100%;
            }
        }
        .price {
            color: $primary-color;
            font-style: italic;
            font-weight: 800;
            font-size: 20px;
            line-height: 25px;
        }
    }

    .icon {
        position: absolute;
        top: 5px;
        right: 10px;
        color: $primary-color;
        z-index: 1;
        fill: $primary-color;
        visibility: hidden;
    }

    .icon path {
        fill: $primary-color;
    }

    .icon_active {
        visibility: visible;
    }

}
