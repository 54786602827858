@import '../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #e7e7e7;
  padding: 20px 0;
  .form {   
    display: flex;
    flex-direction: row;
    align-items: center;
    .input {
      width: 100%;
      &__wrapper {
        width: auto;
        height: 110px;
        @media screen and (min-width: $desktop) {
          width: 400px;
        }
      }
    }  
    .buttons_wrap {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-top: -50px;
      .button {
        height: 40px;
        margin: 8px;

      }
      @media screen and (min-width: $desktop) {
        margin-top: 5px;
        flex-direction: row;
      }
    }
    
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    line-height: 38px;
    font-family: "Sen", sans-serif;
    font-weight: bold;
    .edit__button {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
    @media screen and (min-width: $desktop) {
      font-size: 32px;
      .edit__button {
        width: 24px;
        height: 24px;        
      }      
    }
  }
}

.image {
  &__wrapper {
    width: 110px;
    height: 110px;
    margin: 5px;    
  }
  width: 110px;
  height: 110px;
  object-fit: cover;  
  border-radius: 15px;
  opacity: 0.5;
  
  &.active {
    border: 4px solid $primary-color;
    opacity: 1;
  }
  @media screen and (min-width: $desktop) {
    width: 210px;
    height: 210px;
    &__wrapper {
      width: 210px;
      height: 210px;
      margin: 5px;    
    }
  }
}