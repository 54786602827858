@import 'src/styles/variables';
@import 'src/styles/mixins';

.blog_single_post {
  position: relative;
  background: #f8f8f8;
  border-radius: 15px;
  transition: box-shadow 0.2s ease-in-out;
  will-change: box-shadow;
  width: 100%;

  @include media-up($desktop) {
    padding: 3rem;
  }

  @include media-down($desktop) {
    height: 100%;
    min-height: 350px;
  }

  @include media-down($mobile) {
    min-height: auto;
    height: auto;
  }

  .buttons_wrap {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    visibility: hidden;
    color: $primary-color;
    z-index: 2;
  }

  .blog_single_post_wrapper {
    transition: opacity 0.2s linear;
    opacity: 1;

    .content_wrapper {
      display: flex;
      flex-direction: column;

      @include media-up($desktop) {
        display: flex;
        flex-direction: row;
      }

      .img_wrap {
        flex-basis: 180px;
        flex-shrink: 0;

        img {
          height: 180px;
          min-width: 180px;
          width: 180px;
          object-fit: cover;
          border-radius: 10px;

          @include media-down($desktop) {
            height: 180px;
            width: 100%;
            border-radius: 0;
            border-top-right-radius: 10px 10px;
            border-top-left-radius: 10px 10px;
          }
        }

        @include media-up($desktop) {
          margin-right: 30px;
        }
      }

      .text_wrapper {
        position: relative;
        padding: 25px 15px;

        @include media-up($desktop) {
          max-width: calc(100% - 210px);
          padding: 0;
        }

        &.without_image {
          @include media-up($desktop) {
            max-width: 100%;
          }
        }

        .post_title {
          margin-top: 0;
          margin-bottom: 0;
          font-weight: normal;
          font-size: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;

          &_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.25em;
          }

          @media screen and (min-width: $desktop) {
            max-width: 100%;
          }
        }

        .post_date {
          display: inline-block;
          color: #999999;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 0.5em;
        }

        .post_content {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 4;
          text-overflow: ellipsis;

          .readmore {
            color: #806bff;
            font-weight: 600;
          }
        }
      }
    }
  }

  &:hover {
    cursor: pointer;

    @include media-up($desktop) {
      .blog_single_post_wrapper {
        opacity: 0.4;
      }

      .buttons_wrap {
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    flex-direction: row;
  }

  .edit_mobile {
    position: absolute;
    top: 20px;
    right: 15px;
    padding: 7px;
    border-radius: 5px;
    font-size: 0;

    path {
      fill: #806bff;
    }
  }

  .edit_mobile_with_img {
    top: 200px;
  }

  .status_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .status {
      text-transform: capitalize;
      margin-bottom: 0;
      color: $font-color;
      font-family: $font-Sen;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;

      &.category {
        margin-left: auto;

        @media screen and (max-width: $desktop) {
          margin-left: 30px;
        }
      }

      &.category:before {
        color: #806bff;
        content: 'category: ';
      }

      &.approved {
        color: $verified-color;
      }

      &.disapproved {
        color: $unverified-color;
      }

      &.pending {
        color: $pending-color;
      }

      @media screen and (max-width: $desktop) {
        margin: 0 15px 10px 0;
      }
    }
  }
}

.actions {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
}

.mob_actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  z-index: 2;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
}
