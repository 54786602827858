@import 'src/styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 200px;

    @media screen and (min-width: $desktop) {
        flex-direction: row;
        align-items: flex-start;
    }

    & > * {
        flex: 1;
    }
}

.biography {
    padding: 20px 15px;
    margin-bottom: 25px;
    border-radius: 15px;
    background-color: #f8f8f8;

    @media screen and (min-width: $desktop) {
        padding: 30px;
        margin-right: 70px;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 14px;
    margin-bottom: 3px;
    border-bottom: 1px solid gainsboro;
}
.space{
    margin-right: 20px;
}

.edit_wrapper {
    text-align: end;
    .button{
        height: 30px;
        padding: 0 20px;
        background-color: $primary_color;
        border-radius: 40px;
        color: white;
        font-weight: bold;
    }
}

.button_small {
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
    text-align: center;
    padding-top: 3px;
    background-color: $primary_color;
    border-radius: 40px;
    color: white;
    font-weight: bold;
    &:disabled {
        background-color: lighten($color: $primary_color, $amount: 10);
        cursor: inherit;
    }
}

.block {
    flex: 1;
}

.title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 29px;
    font-family: $font-Sen;
    font-weight: normal;

    @media screen and (min-width: $desktop) {
        font-size: 24px;
        line-height: 24px;
    }
}

.list {
    list-style: disc;
    padding: 0 20px;
    margin-bottom: 34px;
    font-size: 14px;
    line-height: 18px;

    @media screen and (min-width: $desktop) {
        font-size: 16px;
        line-height: 20px;
    }

    &__item {
        padding: 8px 0;
    }

    .years {
        display: inline-block;
        margin-top: 5px;
        font-size: 14px;
        color: rgba(63, 64, 65, 0.3);

        @media screen and (min-width: $desktop) {
            margin-top: 10px;
        }
    }
}
.input_control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin: 10px 20px 10px 0;
    border-bottom: 1px solid gainsboro;

    .control {
        position: relative;
        display: inline-block;
        width: 100%;

        .input {
            width: 320px;
            padding: 5px 10px;
            margin: 5px 0;
            border: 1px solid #e7e7e7;
            border-radius: 5px;
            font-family: inherit;
            font-size: 16px;
            line-height: 28px;
            color: inherit;
            box-sizing: border-box;

            &.link {
                width: 410px;
            }

            &.date {
                width: 80px;
                margin-right: 10px;
                padding: 5px 5px 5px 10px;
            }
        }
    }
}
