@import '../../styles/variables';
@import '../../styles/mixins';

.qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__btn {
        @include reset-btn;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 52px;
        height: 52px;
        border-radius: 50%;
        border: 1px solid #E7E7E7;
        font-size: 0 !important;

        background-color: #fff;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: #e7e7e7;
        }

        &.disabled {
            opacity: .4;
        }

        @media screen and (min-width: $desktop) {
            width: auto;
            height: auto;
            margin-top: 60px;
            padding: 10px 20px;
            border-radius: 100px;
            font-size: 16px !important;
            line-height: 22px;
            font-weight: bold;
            font-family: $font-Sen;
            color: inherit;

            .icon {
                display: flex;
                margin-right: 10px;
                width: 24px;
                height: 24px;
            }
        }
    }

    &__download_btn {
        width: 100%;
        font-size: 13px;

        @media screen and (min-width: $desktop) {
            display: flex !important;
            margin-left: auto;
            margin-right: auto;
            max-width: 200px;
        }
    }
}

.titleCenter {
  text-align: center;
  width: 100%;
}
