@import 'src/styles/variables';

.breadcrumbs {
  background-color: #f7f7f7;
  font-size: 16px;
  line-height: 20px;

  .container {
      display: flex;
      align-items: center;
  }

  .list {
      display: none;
      align-items: center;
      min-height: 80px;

      @media screen and (min-width: $desktop) {
          display: flex;
      }
  }

  .item {
      display: inline-flex;
      align-items: center;

      &:last-child {
          font-weight: bold;
          color: #c7c7c7;

          .arrow {
              display: none;
          }
      }

      .link {
          color: inherit;
          text-decoration: none;
      }
  }

  .back {
      display: inline-flex;
      min-height: 60px;
      align-items: center;

      @media screen and (min-width: $desktop) {
          display: none;
      }
  }

  .arrow {
      margin: 0 20px;

      path {
          fill: #c7c7c7;
      }
  }
}
