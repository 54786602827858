@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.root {
  border: 1px solid $border-color;
  border-radius: 1.5rem;
  background-color: #fff;
  padding: 2rem 0;
  margin-top: 80px;
  margin-bottom: 80px;

  @include media-up($desktop) {
    padding: 3rem 0;
  }

  .title {
    font-size: 28px;
    line-height: 34px;
    font-family: $font-Sen;
    color: $font-color;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1.5rem;
    margin-left: 2rem;

    @include media-up($desktop) {
      margin-left: 3rem;
    }
  }

  .list {
    margin-top: 1.5rem;
  }

  .item {
    border: 1px solid $border-color;
    border-radius: 1.5rem;
    padding: 1.5rem;
    font-size: 14px;
    margin-left: 2rem;
    margin-right: 2rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    & > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    tbody tr:nth-child(odd) {
      background-color: transparent;
    }

    td,
    th {
      border-bottom: none;
      padding: 0;
      line-height: unset;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .itemHeader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .itemFooter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
      width: 100%;
      font-size: 12px;
      @media screen and (min-width: $mobile) {
        font-size: 14px;
      }
    }
  }
}

.wrapper {
  overflow-x: auto;
}

.historyTable {
  border-collapse: collapse;

  thead th {
    font-weight: 600;
    color: rgba($font-color, 0.4);
    text-align: left;
  }

  tbody tr:nth-child(odd) {
    background-color: #fafafa;
  }

  tbody td {
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 10px;
    @media screen and (min-width: $mobile) {
      font-size: 14px;
    }
  }

  th {
    line-height: 45px;
    border-bottom: 1px solid #e7e7e7;
  }

  th,
  td {
    padding-left: 3rem;
    white-space: nowrap;

    &:last-child {
      padding-right: 3rem;
      text-align: right;
    }

    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}
