@import '../../../styles/variables';

.address {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: $font-color;

  @media screen and (min-width: $desktop) {
      font-size: 20px;
      line-height: 28px;
      padding-top: 3px;
  }

  .flag {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    border-radius: 50px;
    background-color: #f0f0f0;
    background: no-repeat center/200%
  }

  .placeholder {
    color: gray;
  }
}
