@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 95px;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 434px;

  @include media-down($orient-laptop) {
    height: 395px;
  }

  @include media-down($gpixel) {
    height: 594px;
  }

}

.subscribe_form {
  position: relative;
  display: flex;
}

.content {
  margin-left: 100px;

  @include media-down($orient-laptop) {
    margin-left: 35px;
  }

  @include media-down($gpixel) {
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  @include media-down($samsung) {
    margin: 0 auto;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.text_container {
  text-align: left;
  margin-bottom: 30px;

  .title {
    color: rgba(255, 255, 255, 1);
    font-family: $font-Sen;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 16px;
    margin-top: 0;
  }

  .desc {
    color: rgba(255, 255, 255, 1);
    font-family: $font-Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    @include media-down($desktop) {
      width: 559px;
    }
  }

  @include media-down($gpixel) {
    .title {
      width: 300px;
    }

    .desc {
      width: 300px;
    }
  }
}

.form_field {
  width: 100%;

  @include media-down($gpixel) {
    display: flex;
    flex-wrap: wrap;
  }

  input {
    height: 52px;
    width: 456px!important;
    border-radius: 50px 50px 50px 50px!important;
    padding: 11px 110px 11px 20px!important;

    @include media-down($gpixel) {
      width: 100%!important;
      margin-bottom: 8px;
    }
  }

  & > div > label {
    display: none;
  }

  .input_button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 169px;
    height: 52px;
    left: 290px;
    border-radius: 50px 50px 50px 50px;
    font-family: $font-Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;

    @include media-down($gpixel) {
      position: relative;
      width: 100%!important;
      left: 0;
    }
  }

}
