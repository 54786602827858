@import '../../../styles/variables';

.features {
    margin: 0 auto;
    padding-bottom: 40px;

    @media screen and (min-width: $desktop) {
        display: flex;
        justify-content: space-between;
        max-width: 940px;
        margin-bottom: 0;
    }

    .feature {
        display: flex;

        flex-basis: 280px;
        margin-bottom: 28px;
        padding: 25px 20px;
        border-radius: 15px;
        background-color: #f8f8f8;


        @media screen and (min-width: $desktop) {
            display: block;
            padding: 40px 25px 50px;
            margin-bottom: 0;
            text-align: center;
        }

        &__icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 100px;
            height: 100px;

            margin-right: 14px;
            border-radius: 50%;
            background-color: #fff;

            @media screen and (min-width: $desktop) {
                margin-bottom: 36px;
                margin-right: 0;
            }
        }

        &__credit {
            position: relative;
            top: 5px;
            left: 5px;
        }

        &__label {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;

            @media screen and (min-width: $desktop) {
                margin-bottom: 20px;
            }
        }

        &__description {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 0;
        }
    }
}
