@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
}

.header {
  margin-bottom: 2rem;
  text-align: right;
}

.content {
  @include media-up($desktop) {
    display: flex;
  }
}

.dropzone,
.image {
  width: 100%;
  height: 325px;
  margin-bottom: 5rem;

  @include media-up($desktop) {
    max-width: 570px;
    margin-right: 85px;
    margin-bottom: 0;
  }
}

.image {
  border-radius: 5px;
  background-color: $border-color;
  object-fit: cover;
}

.list {
  & > *:not(:last-child) {
    margin-bottom: 3rem;
  }

  @include media-up($desktop) {
    max-width: 590px;
  }
}

.group {
  text-align: center;

  @include media-up($tablet) {
    text-align: left;
  }
}

.question {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0.5rem;
}

.answer {
  margin-bottom: 0;
}
