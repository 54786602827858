@import 'src/styles/variables';
.dnd_wrapper {
  display: flex;
  justify-content: center;

  &_drag_file {
    border: 1px dashed $outline-color;
    border-radius: 5px;
    height: 200px;
    transition: .4s;

    &:hover {
      border-color: $primary-color;
    }
  }

  .preview_wrapper {
    position: relative;
    width: 100%;
    background-color: black;
    transition: .6s;

    @media screen and (min-width: $tablet) {
      width: 60%;
    }

    &_drag_file {
      background-color: transparent;
    }

    &:hover {
      .preview {
        opacity: 0.6;
      }
      .preview_message {
        opacity: 1;
      }
    }

    .preview {
      opacity: 1;
      display: block;
      width: 100%;
      transition: .2s ease-in;
      backface-visibility: hidden;
      object-fit: contain;

      &_disabled {
        display: none;
      }
    }

    .preview_message {
      transition: .4s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      &_visible {
        opacity: 1;
      }

      .preview_text {
        color: white;
        font-size: 12px;
        font-family: $font-Mulish;
        font-weight: 600;

        @media screen and (min-width: $tablet) {
          font-size: 14px;
        }

        &_file{
          color: $font-color;
        }

        &_supported {
          color: $c-text-light;
        }
      }
    }
  }
}
