@import 'src/styles/variables';

.footer {
  padding: 50px 0 20px 0;
  border-top: 5px solid #F6F6F6;
  background-color: #ffffff;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  &:last-child {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $desktop) {
      margin-top: 100px;
      flex-direction: row;
      margin-right: 60px;
    }
  }
}

.payment_section {
  background-color: #EDEDED;
  width: 100%;

  .payment_logo_icon {
    display: flex;
    width: 55%;
    justify-content: space-between;
    padding: 10px 0;
  }
}

.draint_rights {
  background-color: #F6F6F6;
  padding: 25px 0 10px 0;

  .rights_content {
    display: flex;
    width: 98%;
    justify-content: space-between;

    .copywriter_wrapper {
      width: 100%;
    }

    .copywriter {
      width: 30%;
      align-self: center;
      font-size: 17px;
      font-weight: 600;
      color: #c7c7c7;
      margin-bottom: 10px;
    }
  }
}

.logo {
  margin: 0 0 20px 0;
}

.list_wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 25px;
  max-width: 210px;

  .list {
    display: flex;
    flex-direction: column;
    padding: 0 1px 0 15px;
    border-left: 1px solid #e7e7e7;
  }

  @media screen and (max-width: $tablet) {
    max-width: 150px;
  }
}


.link {
  display: inline-block;
  color: $font-color;
  font-family: $font-Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin: 14px 0;
  text-decoration: none;
  transition: color .3s ease-in-out;
  will-change: color;

  &_a_link {
    display: flex;
    align-items: baseline;
    span {
      margin-left: 5px;
    }
  }

  &.active {
    color: inherit !important;
  }

  &:hover {
    color: $primary-color !important;
  }

  @media screen and (min-width: $desktop) {
    font-size: 14px;
  }
}

.label {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.subtitle {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.subnav {
  flex-grow: 1;

  @media screen and (min-width: $desktop) {
    flex-grow: 1.2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .list:first-child {
      position: relative;
      left: -30px;
    }
  }
}

.icons {
  display: flex;
  color: #acacac
}

.icon {
  width: 30px;
  height: 30px;
  color: inherit;

  &:not(:last-child) {
    margin-right: 43px;
  }

  &__twitter {
    width: 36px;
  }
}

.climate_wrapper {
  display: flex;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-height: 125px;
  border-radius: 10px;
  padding: 30px 20px 30px 20px;
  margin-top: 55px;
  align-items: center;

  @media screen and (min-width: $desktop) {
    width: 320px;
  }

  .climate {
    width: 20%;
    margin-right: 30px;
  }

  .climate_info {
    font-family: $font-Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #737373;
  }
}

.form_field {
  width: 100%;
  position: relative;
  margin-top: 20px;
  @media screen and (min-width: $desktop) {
    width: 235px;
    margin-top: 0;
  }

  input {
    height: 52px;
    padding: 11px 110px 11px 20px!important;
    @media screen and (min-width: $desktop) {
      padding: 11px 20px!important;
    }
  }

  & > div > label {
    display: none;
  }

  .input_button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 90px;
    height: 52px;
    @media screen and (min-width: $desktop) {
      left: 230px;
    }

    border-radius: 0 5px 5px 0;
    font-family: $font-Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
  }

  .input_info {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 135px;
    right: 0;
    width: 100%;
    font-family: $font-Mulish;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 36px;
  }
}

.footer_wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 50px;

  .logo_section {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
  }

  .nav_list {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
}

.list_title {
  font-family: $font-Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: rgba(63, 64, 65, 0.4);
}

