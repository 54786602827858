@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {

}

.title {
  font-size: 24px;
  font-family: $font-Sen;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 1rem;

  @include media-up($desktop) {
    margin-bottom: 2rem;
  }
}

.item {
  display: flex;
  padding: 2rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }
}

.content {

}

.image {
  height: 100px;
  width: 120px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 1rem;
  flex-shrink: 0;

  @include media-up($desktop) {
    height: 120px;
    width: 160px;
    border-radius: 8px;
    margin-right: 1.8rem;
  }
}

.label {
  font-size: 20px;
  line-height: 25px;
  font-family: $font-Mulish;
  font-weight: 800;
  margin-bottom: 1rem;

  @include media-up($desktop) {
    font-size: 24px;
    line-height: 32px;
    font-family: $font-Sen;
    font-weight: 700;
    margin-bottom: 2rem;
  }
}

.time {
  font-size: 14px;
  font-style: italic;

  @include media-up($desktop) {
    font-size: 16px;
    font-family: $font-Lato;
  }
}
