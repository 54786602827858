@import 'src/styles/variables';

.request_btn {
  padding: 15px 20px;
  font-size: 18px;
  background-color: $primary-color;
  border-radius: 50px;
  color: white;
  font-family: 'Sen', sans-serif;
  font-weight: 600;
  width: 100%;
  transition: 400ms;
  &:hover {
    background-color: darken($primary-color, 4%);
  }

  &.disabled {
    background-color: #f5f7fa;
    color: $disabled-text;
    cursor: default;
  }
}
