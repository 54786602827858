#background-uploader-container {

  .upload-input {
    display: none;
  }

  .progress-circular {
    position: absolute;
    top: 18px;
  }

  .photo-icon {
    position: absolute;
    font-size: 30px;
    top: 7px;
    left: 25px;
  }

  .upload-button {
    position: relative;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 5px;
    font-size: 13px;
    height: 60px;
  }
}


