@import '../../../styles/variables';

.container {
  margin-top: 20px;
  text-align: center;

  @media screen and (min-width: $desktop) {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.price {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  text-shadow: 1px 1px 2px white;

  label {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    font-weight: bold;
    color: #c7c7c7;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  label {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-transform: none;
    color: #c7c7c7;
  }
}

.chart {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;

  @media screen and (min-width: $desktop) {
    margin-bottom: 0;
  }

  &__circle {
    transform: rotate(-90deg);
    transform-origin: center;
  }
}

.details {
  flex-basis: 370px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 25px;
  @media screen and (min-width: $iPhone5) {
    font-size: 12px;
  }

  @media screen and (min-width: $iPhone6) {
    font-size: 15px;
  }

  @media screen and (min-width: $mobile) {
    font-size: 17px;
  }

  b {
    font-weight: normal;
  }
}

.circle {
  display: inline-block;

  &::before {
    content: '';
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 10px;
    border-radius: 50%;
  }

  &__big::before {
    position: relative;
    top: 3px;
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  &__active::before {
    background-color: $success-color;
  }

  &__disable::before {
    background-color: $error-color;
  }

  &__pending::before {
    background-color: rgba(63, 64, 65, 0.3);
  }

  &__payout::before {
    background-color: #AC9EFF;
  }

  &__fee::before {
    background-color: #FF6BF9;
  }

  &__commissions::before {
    background-color: #4656C1;
  }
}
