@import '../../../styles/variables';

.input {
  width: 100%;
  &__wrapper {
    width: 250px;
    @media screen and (min-width: $desktop) {
      width: 400px;
    }
  }
}