@import '../../styles/variables';

.badge {

  display: inline-block;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
  color: white;
  background-color: $c-purple;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8em;
  border-radius: $radius;

  &.blue {

    background-color: $c-blue;

  }

  &.red {

    background-color: $c-red;

  }

  &.green {

    background-color: $c-green;

  }

  &.orange {

    background-color: $c-orange;

  }
}
