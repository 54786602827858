@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
  @include text-caption(#fff);

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: $primary-color;
  border-radius: 50px;
  min-width: 60px;
  height: 60px;
  transition: background-color 0.4s ease-out;

  &:hover,
  &:focus {
    background-color: darken($primary-color, 5%);
  }

  &.sm {
    height: 50px;
    min-width: 50px;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      margin-left: 7px;
    }
  }

  @include media-down($desktop) {
    height: 50px;
  }
}

