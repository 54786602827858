@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.mob_search {
  width: 310px;
  height: 100%;

  &__header {
    width: 100%;
    padding: 33px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E7E7E7;

    .arrow {
      transform: rotate(180deg);
      position: absolute;
      top: 28px;
      left: 24px;
    }

    .title {
      font-family: $font-Muli;
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }

  &__results {
    max-height: 360px;
    overflow-y: auto;

    @include with-scroll;

    @media screen and (max-width: 320px) {
      max-height: 250px;
    }

    .result {
      padding: 16px 24px;
      border-bottom: 1px solid #E7E7E7;
      text-transform: capitalize;

      .data {
        font-family: $font-Lato;
        font-size: 16px;
        display: flex;
        align-items: center;
      }

      .type {
        font-family: $font-Lato;
        font-size: 12px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: rgba(63, 64, 65, 0.4);
      }

      @media screen and (max-width: 320px) {
        padding: 8px 20px;

        .data {
          font-size: 14px;
        }

        .type {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }

  &__form {
    padding: 24px;

    .input {

      &:focus {
        border-color: $primary-color;
      }
    }

    .label {
      text-transform: none;
      font-family: $font-Lato;
      font-style: italic;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: rgba(63, 64, 65, 0.4);
      padding-left: 10px;
    }

    .icon {
      path {
        fill: $primary-color;
      }

      width: 20px;
      height: 20px;
      margin-top: 4px;

      &__spinner {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
