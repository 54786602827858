@import 'src/styles/variables';
@import 'src/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0.5rem;
  color: inherit;
  text-transform: unset;
  padding-left: 0.5rem;
}

.required::after {
  position: relative;
  content: '*';
  color: $error-color;
  left: 5px;
}

.input {
  font-family: inherit;
  font-size: 16px;
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  min-height: 50px;
  padding-left: 20px;
  padding-right: 20px;

  &.atelier {
    width: 90%;
    margin-right: 20px;

    @include media-down($desktop) {
      margin-right: 5px;
    }
  }
}

.input::placeholder {
  font-style: italic;
}

.list {
  margin-top: 0.5rem;

  & > * {
    padding: 0.5rem;
    max-width: 100%;
  }

  &:not(.empty) {
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    flex-wrap: wrap;
  }
}

.inputWrapper {
  position: relative;
}

.icon {
  width: 16px;
  height: 16px;

  svg {
    path {
      fill: $primary-color;
    }
  }

  &:disabled {
    cursor: initial;

    svg {
      path {
        fill: #e7e7e7;
      }
    }
  }
}
