@import '../../../styles/variables';

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  .image {
    max-width: 100%;
    max-height: 220px;
  }

  .text {
    margin: 20px 0;
    font-family: $font-Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }

  .empty_text {
    min-height: 115px;
  }

  .empty_image {
    min-height: 220px;
  }

  .blur__text {
    position: sticky;
    width: 100%;
    height: 79px;
    bottom: -28px;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }

  .legal__text {
    margin: 20px 10px 20px 0;
    line-height: 28px;
    text-align: left;

    h1, h2 {
      font-size: 20px;
      font-weight: 800;
      line-height: 25px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px;

      li {
        list-style: inside;
      }
    }
  }
}
