@import '../../../styles/variables';
@import '../../../styles/mixins';

.title {
  font-family: $font-Sen;
  font-weight: 400;
  font-size: 28px;
  color: $font-color;

  @include media-up($desktop) {
    font-size: 40px;
  }
}
