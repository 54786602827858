@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.artwork {
  padding: 24px 0 25px 0;

  & > .image__wrapper {
    display: none;
  }

  @media screen and (min-width: $desktop) {
    display: flex;
    align-items: flex-start;
    padding: 0;

    & > .image__wrapper {
      display: block;
    }
  }

  .image {
    position: relative;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    background-color: #e7e7e7;

    @media screen and (min-width: $desktop) {
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }

    &__wrapper {
      position: relative;
      width: 130px;
      margin-right: 15px;

      @media screen and (min-width: $desktop) {
        width: 200px;
        flex-shrink: 0;
        margin-right: 43px;
      }

      &::after {
        position: absolute;
        content: '';
        height: 30px;
        left: 17px;
        right: 17px;
        bottom: 0;
        opacity: .7;
        background-color: #fff;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
      }
    }


    @media screen and (min-width: $desktop) {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      background-color: #cecece;
    }
  }

  .content {

    @media screen and (min-width: $desktop) {
      flex-grow: 1;
    }
  }

  .header {
    @media screen and (min-width: $desktop) {
      border-bottom: 1px solid #e7e7e7;

      .image__wrapper {
        display: none;
      }
    }

    &__content {
      display: flex;
      padding: 1rem 0;
      align-items: flex-start;
      justify-content: space-between;

      @media screen and (min-width: $desktop) {
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    font-family: $font-Sen;
    font-weight: normal;
    text-decoration: none;
    color: $font-color;
    word-break: break-all;
    @include text-hover-primary;

    @media screen and (min-width: $desktop) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .name,
  .address {
    font-size: 16px;
    line-height: 24px;
    font-style: italic;
    word-break: break-all;

    @media screen and (min-width: $desktop) {
      line-height: 20px;
    }
  }

  .name {
    color: $primary-color;
    font-weight: 800;

    @media screen and (min-width: $desktop) {
      font-weight: bold;
    }
  }

  .params {
    display: none;
    @media screen and (min-width: $desktop) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 18px 0 18px 0;
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 10px;
    }
    &_mobile {
      border-top: 1px solid #e7e7e7;
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
      @media screen and (min-width: $desktop) {
        display: none;
      }

      .param_mobile {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: .5em;
      }
    }
  }

  .param {
    font-size: 14px;
    font-family: Mulish, serif;
    margin-right: 10px;

    &__weight {
      width: 75px;
    }

    &__label {
      display: inline-block;
      font-weight: bold;
      color: #c7c7c7;
      width: 100px;
      font-size: 14px;
      @media screen and (min-width: $desktop) {
        line-height: 18px;
        margin-bottom: .5rem;
      }
    }

    p {
      @media screen and (min-width: $desktop) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.wrapper {
  .section {
    margin-top: 28px;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
  }

  .title {
    margin-top: 0;
    border-bottom: 1px solid #e7e7e7;
    text-align: left;
  }

  .order {
    border-bottom: 2px solid #e7e7e7;

    @media screen and (min-width: $desktop) {
      padding: 60px 0 50px;

      &:nth-child(2) {
        padding-top: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .pagination {
    padding: 1.5rem 0;
  }

  .offers__title {
    margin-top: 50px;
  }

  .button {
    @include reset-btn;
    padding: 13px 15px;
    border-radius: 35px;
    font-size: 18px;
    line-height: 24px;
    transition: background-color .2s ease-in-out;

    &.danger {
      background-color: $error-color;

      @media screen and (min-width: $desktop) {
        margin-left: auto;
      }

      &:hover {
        background-color: darken($error-color, 5);
      }
    }

    &.success {
      margin-left: 15px;
      font-weight: bold;
      color: white;
      background-color: $success-color;

      &:hover {
        background-color: darken($success-color, 5);
      }
    }

    &.fixed {
      min-width: 130px;
    }
  }

  .request_button {
    display: flex;
    justify-content: flex-end;
  }
}

.container {
  padding: 0 30px;
}

.notification {
  margin-bottom: 35px;
}
