@import 'src/styles/variables';

.artist_collector_header {
  text-align: center;
  width: 100%;
  margin: 10px 0 15px 0;
  font-family: $font-Mulish;
  font-size: 20px;
  font-weight: bold;

  &_desktop {
    display: none;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 210px;
  height: 44px;
  background-color: $c-bg_dark;
  border-radius: 50px;
  padding: 5px;

  &_mobile {
    max-width: 290px;
  }

  .button {
    padding: 5px 18px 5px 18px;
    font-family: $font-Mulish;
    font-size: 14px;
    color: $black-font;

    &_mobile {
      padding: 5px 45px 5px 45px;
    }

    &_active {
      background-color: white;
      border-radius: 50px;
      box-shadow: $box-shadow-default;
      font-weight: 700;
      color: var(--primary-color, $primary-color);
      cursor: default;
    }
  }
}
