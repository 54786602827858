@import 'src/styles/variables';
@import 'src/styles/mixins';

.pagination {

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 10px;
        margin-bottom: 60px;
        border-top: 2px solid #f6f6f6;
        border-bottom: 2px solid #f6f6f6;

    @include media-up($desktop) {
            justify-content: space-between;
        }

      &.hidden {
        display: none;
      }
    }
}

.page {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    height: 28px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    font-family: $font-Lato;
    transition: all .2s ease-in-out;

    &:last-child {
        margin-right: 0;
    }

    @include media-up($desktop) {
        min-width: 38px;
        height: 38px;
        margin-right: 10px;
        font-size: 20px;
        line-height: 24px;
    }

    &:hover {
        background-color: #e7e7e7;
    }

    &.active {
        color: white;
        background-color: $primary-color;

        &:hover {
            background-color: $primary-color;
        }
    }

    &.dots {
        color: #e0e0e0;

        &:hover {
            background-color: transparent;
        }
    }
}

.pages {
    margin: 20px 20px 20px 0;

    @include media-up($desktop) {
        margin: 0;
    }
}

.buttons {
    margin: 20px 0;

    @include media-up($desktop) {
        margin: 0;
    }
}

.button {
    font-size: 0;

  @include media-down($tablet) {
    width: 48px;
    height: 48px;
  }

    @include media-up($desktop) {
        &:first-child {
            margin-right: 50px;
        }
    }

    &:hover {
        color: $primary-color;

        .icon path {
            fill: $primary-color;
        }
    }

    &:disabled {
        color: inherit;
        opacity: .1;
        cursor: default;
    }

    .icon {

        @include media-up($desktop) {
            margin-left: 20px;
        }


        &.prev {
            margin-right: 20px;
            margin-left: 0;
            transform: rotate(180deg);
        }
    }

    @include media-up($desktop) {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        font-family: $font-Lato;
    }
}
