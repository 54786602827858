@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  font-family: $font-Sen;
  margin: 30px 0 25px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  @include media-up($mobile) {
    margin-top: 0;
  }
}

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60px;
  padding: 46px 0 40px;
  margin-bottom: 30px;
  background-color: #fff;

  &__wrapper {
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    &_fixed {
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
    }

    &_hide {
      z-index: 1;
    }

    @include media-up($desktop) {
      position: relative;
      margin-top: 60px;
      z-index: 1;
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: #fff;
      border: 1px solid #F5EDED;
      min-width: 40px !important;
      max-width: 40px !important;
      height: 40px;

      & > svg {
        width: 18px;
        height: 18px;
      }

      &.active {
        background-color: $primary-color;
      }

      &.finish {
        background-color: #49DF88;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__label {
      height: 30px;
      font-family: $font-Lato;
      font-size: 14px;
      line-height: 24px;
      font-style: normal;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(63, 64, 65, 0.4);

      @include media-down($iPhone6) {
        font-size: 12px;
      }

      &.active {
        color: $primary-color;
        font-weight: bold;
      }
    }

    &__dots {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 5px;
      padding-bottom: 30px;
      width: 20px;
      height: 70%;

      &.active > div {
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
    }

    &__dots > div {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      border: 1px solid #F5EDED;
      background-color: #F5EDED;
    }
  }

  @include media-up($iPhone6) {
    .step {
      &__dots {
        margin: 0 10px;
        width: 30px;
      }

      &__dots > div {
        width: 5px;
        height: 5px;
      }
    }
  }

  @include media-up($mobile) {
    height: 91px;

    &__wrapper {
      flex-direction: column;

    }

    .step {

      &__icon {
        min-width: 50px !important;
        max-width: 50px !important;
        height: 50px;

        & > svg {
          width: 26px;
          height: 26px;
        }
      }

      &__arrow {
        display: none;
      }

      &__dots {
        margin: 0 40px;
        width: 50px;
      }

      &__dots > div {
        width: 7px;
        height: 7px;
      }
    }
  }

  @include media-up($tablet) {
    .step {
      flex-direction: column;

      &__icon {
        min-width: 60px !important;
        max-width: 60px !important;
        height: 60px;

        & > svg {
          width: 32px;
          height: 32px;
        }
      }

      &__dots {
        margin: 0 40px;
        width: 70px;
      }

      &__dots > div {
        width: 7px;
        height: 7px;
      }
    }
  }
}

.spacer {
  height: 146px;

  @include media-up($desktop) {
    display: none;
  }
}
